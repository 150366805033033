import { fetch, Key } from '@maxtropy/components';
import { Organization } from './customer-user';

export interface OrganizationResponse {
  data: Organization;
  parent?: number;
  children: OrganizationResponse[];
}

export function getOrganizationWithCodeWithCurrent(): Promise<OrganizationResponse> {
  return fetch(`/api/device/organization-with-code-with-current`);
}
