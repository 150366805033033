import { FC, useEffect, useRef, useState } from 'react';
import { Button, FormTitle, Modal, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { apiV2BsaStrategyCalendarInfoPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import TopCard from './TopCard';
import { ActionTypeEnum, BsaStrategyCalendarContext, MONTH_FORMAT, StrategyCalendarData } from './const';
import TopActionBar from './TopActionBar';
import LeftCalendarComponent from './LeftCalendarComponent';
import RightComponent from './RightComponent';
import { Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

const ERRCODE = -10000;

const BsaStrategy: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams();
  const mainContentRef = useRef<HTMLDivElement>(null);
  // 查看、投放、启动、停止、删除
  const [actionType, setActionType] = useState<ActionTypeEnum>(ActionTypeEnum.VIEW);
  // 查看选中的日期
  const [viewDate, setViewDate] = useState<Dayjs>(dayjs());
  // 当前日历显示的月份
  const [calendarShowDate, setCalendarShowDate] = useState<string>(dayjs().format(MONTH_FORMAT));
  // 选择的日期列表
  const [selectedDateList, setSelectedDateList] = useState<Dayjs[]>([]);
  // 日历单元格高度
  const [cellHeight, setCellHeight] = useState(0);
  // 日历策略数据
  const [strategyCalendarData, setStrategyCalendarData] = useState<StrategyCalendarData[]>([]);
  // 操作的表单，投放、启动、停止、删除等
  const [getActionFormData, setGetActionFormData] = useState<any>();

  useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (mainContentRef.current) {
      // 监听窗口变化，重新计算单元格高度
      resizeObserver = new ResizeObserver(() => {
        setCellHeight((mainContentRef.current!.clientHeight - 100) / 6);
      });
      resizeObserver.observe(mainContentRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const day = dayjs(calendarShowDate);
    apiV2BsaStrategyCalendarInfoPost({
      neoBsaId: id,
      year: day.year(),
      month: day.month() + 1,
    })
      .onError(err => {
        const { status, errorMessage } = err?.cause;
        if (status === ERRCODE) {
          setStrategyCalendarData([]);
        } else {
          Modal.error({
            title: errorMessage || '未知错误！ 请联系管理员。',
            okText: '确定',
          });
        }
        throw err;
      })
      .then(res => {
        setStrategyCalendarData(pre => {
          return [...pre, ...(res.list ?? [])];
        });
      });
  }, [calendarShowDate]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), { name: '充放策略' }]} className={styles.wrap}>
      <FormTitle
        title="充放策略"
        extraContent={
          <Space size={8}>
            <Button
              onClick={() => {
                window.open('/energy-storage/basic/bsa/strategyHistory/' + id);
              }}
            >
              投放记录
            </Button>
            <Button
              icon={<SyncOutlined />}
              disabled={actionType !== ActionTypeEnum.VIEW}
              onClick={() => {
                window.location.reload();
              }}
            >
              刷新
            </Button>
          </Space>
        }
      />
      <BsaStrategyCalendarContext.Provider
        value={{
          actionType,
          setActionType,
          viewDate,
          setViewDate,
          calendarShowDate,
          setCalendarShowDate,
          selectedDateList,
          setSelectedDateList,
          strategyCalendarData,
          setStrategyCalendarData,
          cellHeight,
          getActionFormData,
          setGetActionFormData,
        }}
      >
        <TopCard></TopCard>
        <TopActionBar></TopActionBar>

        <div className={styles.mainContent} ref={mainContentRef}>
          <LeftCalendarComponent></LeftCalendarComponent>
          <RightComponent></RightComponent>
        </div>
      </BsaStrategyCalendarContext.Provider>
    </Wrapper>
  );
};

export default BsaStrategy;
