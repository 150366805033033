import { NodeType, NodeTypeLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import classNames from 'classnames';
import styles from './index.module.scss';
import React, { FC } from 'react';
import { Tag } from '@maxtropy/components';

interface TitleTagProps {
  title: string;
  nodeType?: NodeType;
}

const TitleTag: FC<TitleTagProps> = props => {
  const { title, nodeType } = props;
  const tagClassName = classNames({
    [styles.source]: nodeType === NodeType.SOURCE,
    [styles.load]: nodeType === NodeType.LOAD,
    [styles.storage]: nodeType === NodeType.STORAGE,
    [styles.transformer]: nodeType === NodeType.TRANSFORMER,
    [styles.distribution]: nodeType === NodeType.DISTRIBUTION,
    [styles.switch]: nodeType === NodeType.SWITCH,
    [styles.measurement]: nodeType === NodeType.NETWORK,
  });
  return (
    <>
      <div className={styles.title}>
        <div className={styles.text}>
          {title}
          <Tag className={tagClassName}>{NodeTypeLabel[nodeType!]}</Tag>
        </div>
      </div>
    </>
  );
};

export default TitleTag;
