import { FC, useMemo } from 'react';
import { EllipsisSpan, Modal, Table } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { v4 } from 'uuid';
import classnames from 'classnames';
import { apiV2BsaControlConfigLogDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import styles from './index.module.scss';

interface DetailDialogProps {
  id: number | undefined;
  setId: (id: number | undefined) => void;
  softwareVersion?: string;
}

interface CommonDataItem {
  id: string;
  field: string;
  originalData: string | number | undefined;
  editedData: string | number | undefined;
}

interface PcsDataItem {
  id: string;
  pcsName: string;
  dataSource: '原始数据' | '编辑后数据';
  ratedPower: number | undefined;
  stackName: string;
  socMax: number;
  socMin: number;
  upstreamTransformerMeterName: string;
}

const SoftwareVersion = 'JSEMS-V1.0';

const DetailDialog: FC<DetailDialogProps> = props => {
  const { id, setId, softwareVersion } = props;

  const { data, loading } = useRequest(
    async () => {
      if (!id) return;
      return apiV2BsaControlConfigLogDetailPost({ id: id! }).then(res => res);
    },
    { refreshDeps: [id] }
  );

  // 顶部第一个表格
  const commonColumns: ColumnsType<CommonDataItem> = [
    {
      title: '字段',
      dataIndex: 'field',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '原始数据',
      dataIndex: 'originalData',
      ellipsis: { showTitle: true },
      render: (v, record) => <EllipsisSpan value={record.field === '三方EMS' ? v : (v ?? '--') + 'kW'}></EllipsisSpan>,
    },
    {
      title: '编辑后数据',
      dataIndex: 'editedData',
      ellipsis: { showTitle: true },
      render: (v, record) => (
        <EllipsisSpan
          value={record.field === '三方EMS' ? v : (v ?? '--') + 'kW'}
          className={classnames(record.originalData !== record.editedData && styles.diffValue)}
        ></EllipsisSpan>
      ),
    },
  ];

  const commonData: CommonDataItem[] = useMemo(() => {
    const beforeData = data?.before;
    const afterData = data?.after;
    const datas: CommonDataItem[] = [
      {
        id: v4(),
        field: '充电需量控制/固定需量/需量阈值',
        originalData: beforeData?.demandThreshold ?? undefined,
        editedData: afterData?.demandThreshold ?? undefined,
      },
      {
        id: v4(),
        field: '充电需量控制/最大需量/允许充电功率',
        originalData: beforeData?.chargeThreshold ?? undefined,
        editedData: afterData?.chargeThreshold ?? undefined,
      },
      {
        id: v4(),
        field: '最大充电功率',
        originalData: beforeData?.maxChargePower ?? undefined,
        editedData: afterData?.maxChargePower ?? undefined,
      },
      {
        id: v4(),
        field: '最大放电功率',
        originalData: beforeData?.maxDischargePower ?? undefined,
        editedData: afterData?.maxDischargePower ?? undefined,
      },
    ];
    if (SoftwareVersion !== softwareVersion) {
      datas.push({
        id: v4(),
        field: '三方EMS',
        originalData:
          (beforeData?.emsDeviceCode ?? '--') + (beforeData?.emsDeviceName ? `(${beforeData?.emsDeviceName})` : '(--)'),
        editedData:
          (afterData?.emsDeviceCode ?? '--') + (afterData?.emsDeviceName ? `(${afterData?.emsDeviceName})` : '(--)'),
      });
    }
    return datas;
  }, [data]);

  // PCS表格
  const pcsData: PcsDataItem[] = useMemo(() => {
    const beforeData = data?.before?.pcsParameters ?? [];
    const afterData = data?.after?.pcsParameters ?? [];
    let finalPcsData: PcsDataItem[] = [];
    afterData.forEach(item => {
      const beforeItem = beforeData.find(beforeItem => beforeItem.pcsDeviceId === item.pcsDeviceId);
      finalPcsData.push({
        id: v4(),
        pcsName: item.pcsName!,
        dataSource: '原始数据',
        ratedPower: beforeItem?.ratedPower,
        stackName: beforeItem?.stackName!,
        socMax: beforeItem?.socMax!,
        socMin: beforeItem?.socMin!,
        upstreamTransformerMeterName: beforeItem?.upstreamTransformerMeterName!,
      });
      finalPcsData.push({
        id: v4(),
        pcsName: item.pcsName!,
        dataSource: '编辑后数据',
        ratedPower: item.ratedPower!,
        stackName: item.stackName!,
        socMax: item.socMax!,
        socMin: item.socMin!,
        upstreamTransformerMeterName: item.upstreamTransformerMeterName!,
      });
    });
    return finalPcsData;
  }, [data]);

  const pcsColumns: ColumnsType<PcsDataItem> = [
    {
      title: 'PCS名称',
      dataIndex: 'pcsName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
      onCell: (_, index) => {
        return {
          rowSpan: index! % 2 === 0 ? 2 : 0,
        };
      },
    },
    {
      title: '修改对比',
      dataIndex: 'dataSource',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: 'PCS额定功率',
      dataIndex: 'ratedPower',
      ellipsis: { showTitle: true },
      render: (v, _record, index) => (
        <EllipsisSpan
          value={(v ?? '--') + 'kW'}
          className={classnames(index % 2 === 1 && v !== pcsData[index - 1].ratedPower && styles.diffValue)}
        ></EllipsisSpan>
      ),
    },
    {
      title: '电池堆名称',
      dataIndex: 'stackName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
      onCell: (_, index) => {
        return {
          rowSpan: index! % 2 === 0 ? 2 : 0,
        };
      },
    },
    {
      title: '电池最小SOC限制',
      dataIndex: 'socMin',
      ellipsis: { showTitle: true },
      render: (v, _record, index) => (
        <EllipsisSpan
          value={v}
          className={classnames(index % 2 === 1 && v !== pcsData[index - 1].socMin && styles.diffValue)}
        ></EllipsisSpan>
      ),
    },
    {
      title: '电池最大SOC限制',
      dataIndex: 'socMax',
      ellipsis: { showTitle: true },
      render: (v, _record, index) => (
        <EllipsisSpan
          value={v}
          className={classnames(index % 2 === 1 && v !== pcsData[index - 1].socMax && styles.diffValue)}
        ></EllipsisSpan>
      ),
    },
    {
      title: '上端变压器电表',
      dataIndex: 'upstreamTransformerMeterName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
      onCell: (_, index) => {
        return {
          rowSpan: index! % 2 === 0 ? 2 : 0,
        };
      },
    },
  ];

  return (
    <Modal
      title="控制配置修改对比"
      onCancel={() => setId(undefined)}
      onOk={() => setId(undefined)}
      open={!!id}
      size="big"
      bodyScroll={true}
    >
      <Spin spinning={loading}>
        <div className={styles.detailDialogContent}>
          <Table rowKey="id" columns={commonColumns} dataSource={commonData} />
          {SoftwareVersion === softwareVersion ? (
            <Table style={{ marginTop: 20 }} rowKey="id" columns={pcsColumns} dataSource={pcsData} />
          ) : null}
        </div>
      </Spin>
    </Modal>
  );
};

export default DetailDialog;
