import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  PopConfirm,
  Select,
  Table,
  message,
  usePaging,
} from '@maxtropy/components';
import styles from './index.module.scss';
import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { Cascader, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeviceTypeTree, DeviceTypes, formatOptionData, getChildNodesByParentIds } from '../../utils';
import { DefaultOptionType } from 'antd/es/cascader';
import {
  V2InstructionConfigPagePostResponse,
  apiV2DeviceDeviceTypeTreePost,
  apiV2DeviceListPost,
  apiV2InstructionConfigDeletePost,
  apiV2InstructionConfigPagePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';

export interface SearchParams {
  commandName?: string;
  buttonName?: string;
  deviceName?: string;
  deviceType?: number[];
}

const columns = [
  {
    title: '指令名称',
    dataIndex: 'instructionName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '按钮名称',
    dataIndex: 'buttonName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标设备',
    dataIndex: 'deviceName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标值',
    dataIndex: 'targetValue',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

type RowInfo = Exclude<V2InstructionConfigPagePostResponse['list'], undefined>[number];
const ControlInsTable = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const deviceType: number[][] | undefined = Form.useWatch('deviceType', form);

  // 添加控制指令按钮
  const hasAddInstructionPermission = useHasPermission(PermissionsType.B_ADDINSTRUCTIONCONFIGURATION);
  // 指令编辑按钮
  const hasEditInstructionPermission = useHasPermission(PermissionsType.B_EDITINSTRUCTIONCONFIGURATION);
  // 指令删除按钮
  const hasDeleteInstructionPermission = useHasPermission(PermissionsType.B_DELETEINSTRUCTIONCONFIGURATION);

  // 表格数据
  const {
    data: list,
    refresh: refreshList,
    loading: tableLoading,
  } = useRequest(
    () =>
      apiV2InstructionConfigPagePost({ ...searchParams, page: pageOffset, pageSize: pageSize }).then(res => {
        setTotalCount(res?.total ?? 0);
        return res?.list;
      }),
    {
      refreshDeps: [pageOffset, pageSize, searchParams],
    }
  );

  // 所属类目
  const { data: deviceTypeData } = useRequest(() => {
    return apiV2DeviceDeviceTypeTreePost();
  });

  // 目标设备下拉
  const { data: targetDeviceOptions } = useRequest(
    async () => {
      if (deviceType?.length === 0 || isNil(deviceType)) {
        return [];
      } else {
        const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree as DeviceTypes[]);
        const res = await apiV2DeviceListPost({ deviceType: deviceTypeIds });
        if (res?.list) {
          return res.list;
        }
      }
    },
    {
      refreshDeps: [deviceType],
    }
  );

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: any) => {
        const deviceTypeIds = getChildNodesByParentIds(values.deviceType, deviceTypeData?.tree as DeviceTypes[]);
        setPageOffset(1);
        setSearchParams({
          ...values,
          deviceType: deviceTypeIds,
        });
      }}
      onReset={() => {
        setSearchParams({});
        form.resetFields();
        setPageOffset(1);
      }}
    >
      <Form.Item label="指令名称" name="instructionName">
        <Input placeholder="请输入指令名称" />
      </Form.Item>
      <Form.Item label="按钮名称" name="buttonName">
        <Input placeholder="请输入按钮名称" />
      </Form.Item>

      <Form.Item name="deviceType" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData as DeviceTypeTree)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
          onChange={() => {
            form.setFieldValue('deviceId', undefined);
          }}
        />
      </Form.Item>

      <Form.Item label="目标设备" name="deviceId">
        <Select
          options={targetDeviceOptions?.map(item => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder="请选择"
          allowClear
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </CustomFilter>
  );

  // 删除
  const deleteBtn = (record: RowInfo) => {
    Modal.confirm({
      title: <span>是否删除该控制指令？</span>,
      icon: <ExclamationCircleOutlined />,
      content: '删除后相关页面设备无法进行指令控制。',
      okText: '继续',
      onOk() {
        apiV2InstructionConfigDeletePost({ id: record.id })
          .then(_ => {
            message.success('删除成功');
            refreshList();
          })
          .catch(_ => {});
      },
      onCancel() {},
    });
  };

  const opColunms = [
    {
      title: '操作',
      width: 150,
      fixed: 'right' as const,
      render: (v: string, record: RowInfo) => (
        <Space size={12}>
          {hasEditInstructionPermission && (
            <Button
              type="link"
              style={{ padding: '4px 0' }}
              onClick={() => navigate(`/microgrid/scheduling/instructionConfig/edit/${record?.id}`)}
            >
              编辑
            </Button>
          )}
          {hasDeleteInstructionPermission && (
            <Button type="link" style={{ padding: '4px 0' }} onClick={() => deleteBtn(record)}>
              删除
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {filters}
      {hasAddInstructionPermission && (
        <div className={styles.button_box}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => navigate('/microgrid/scheduling/instructionConfig/edit')}
            type="primary"
          >
            添加控制指令
          </Button>
        </div>
      )}

      <div className={styles.table_box}>
        <Table loading={tableLoading} rowKey="id" dataSource={list ?? []} columns={[...columns, ...opColunms]} />
      </div>

      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default ControlInsTable;
