import { NodeType, NodeTypeLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { Tag } from 'antd';
import { isNil } from 'lodash-es';
import React from 'react';
import classNames from 'classnames';

interface Props {
  id?: number;
  styles: {
    readonly [key: string]: string;
  };
  title: string;
  delMethod?: any;
  isDetail: boolean;
  isPreview?: boolean;
  nodeType?: NodeType;
  onEdit: () => void;
  onCancel?: () => void;
  forceUpdate?: () => void;
  shouldDeleteElement?: (state: boolean) => void;
}
const Title: React.FC<Props> = ({ id, isDetail, styles, title, isPreview, nodeType, onEdit }) => {
  const tagClassName = classNames({
    [styles.source]: nodeType === NodeType.SOURCE,
    [styles.load]: nodeType === NodeType.LOAD,
    [styles.storage]: nodeType === NodeType.STORAGE,
    [styles.transformer]: nodeType === NodeType.TRANSFORMER,
    [styles.distribution]: nodeType === NodeType.DISTRIBUTION,
    [styles.switch]: nodeType === NodeType.SWITCH,
    [styles.measurement]: nodeType === NodeType.NETWORK,
  });

  return (
    <>
      <div className={styles.title}>
        <div className={styles.text}>
          {isDetail ? '查看' : !isNil(id) ? '编辑' : '创建'}
          {title}
          <Tag className={tagClassName}>{NodeTypeLabel[nodeType!]}</Tag>
        </div>

        {isDetail && (
          <div>
            {!isPreview && (
              <a
                href=" "
                className={styles.editBtn}
                onClick={e => {
                  e.preventDefault();
                  onEdit();
                }}
              >
                编辑
              </a>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Title;
