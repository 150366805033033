import {
  Button,
  Checkbox,
  Form,
  FormContent,
  FormTitle,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  SubContent,
  Table,
  Tooltip,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import React, { Key, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ActionType,
  ActionTypeTime,
  ActionTypeTypeDisplay,
  ChargingAndDischargingPower,
  ChargingAndDischargingPowerplay,
  PeaksAndValleysType,
  PeaksAndValleysTypePowerplay,
  PricedisplayType,
  PricedisplayTypeDisplay,
  PVPeriods,
  PVPeriodsDisplay,
  StrategyTemplateType,
  StrategyTemplateTypeDisplay,
} from '../type';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import {
  FileSearchOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  RightCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import ShowInput from '@/components/ShowInput';
import {
  apiV2BsaControlConfigPost,
  apiV2BsaStrategyTemplateAddPost,
  apiV2BsaStrategyTemplateEditPost,
  apiV2BsaStrategyTemplateEpDetailPost,
  apiV2BsaStrategyTemplateGetPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import ChargeDisChangeTime from './components/ChargeDisChangeTime';
import { isNil } from 'lodash-es';
import { columnsWithCustomCustom, columnsWithCustomFollowMax, columnsWithFollowPrice } from '../common';
import {
  ChargeList,
  ChargeListFormat,
  CreatePriceFormProps,
  formatEndtime,
  formatSubmitTime,
  getPriceTimeIndex,
  getTimeString,
} from '../utils';

const StrategyTemplateEdit = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaId = urlSearchParams.get('bsaId') || undefined;
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const periodTypes: PVPeriods | undefined = Form.useWatch('periodTypes', form);
  const chargingAndDischargingPower: ChargingAndDischargingPower | undefined = Form.useWatch(
    'chargingAndDischargingPower',
    form
  );
  const plainAndValleyTypeList: number[] | undefined = Form.useWatch('plainAndValleyTypeList', form);
  const peakAndSharpTypeList: number[] | undefined = Form.useWatch('peakAndSharpTypeList', form);
  const price: ActionTypeTime[] | undefined = Form.useWatch('price', form);
  const timePeriodGranularity: PricedisplayType | undefined = Form.useWatch('timePeriodGranularity', form);
  const epTemplateId: number | undefined = Form.useWatch('epTemplateId', form);

  const [displayType, setDisplayType] = useState<PricedisplayType>();

  const [updateState, updateFn] = useUpdate();

  const routes = useMemo(() => {
    return [{ name: `${id ? '编辑' : '新建'}策略模板` }];
  }, [id]);

  // 查询上下线
  const { data: bsaConfigData } = useRequest(
    () =>
      apiV2BsaControlConfigPost({ id: Number(bsaId) }).then(res => {
        return res;
      }),
    {
      ready: !!bsaId,
      refreshDeps: [bsaId],
    }
  );

  // 查询详情
  const { data, loading: tableLoading } = useRequest(
    () =>
      apiV2BsaStrategyTemplateGetPost({ id: Number(id) }).then(res => {
        return res;
      }),
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  useEffect(() => {
    if (!isNil(periodTypes) && !isNil(chargingAndDischargingPower)) {
      if (periodTypes === PVPeriods.FollowEpriceTemplate) {
        if (chargingAndDischargingPower === ChargingAndDischargingPower.Custom) {
          const allList = [plainAndValleyTypeList, peakAndSharpTypeList].flat().filter(i => !isNil(i));
          form.setFieldsValue({
            chargingAndDischargingPeriodDetail: allList.map(item => {
              return {
                key: Math.random(),
                type: ChargeList.includes(item!) ? ActionType.Charge : ActionType.DisCharge,
                peakValleyType: item,
                time: PeaksAndValleysTypePowerplay[item as PeaksAndValleysType],
                startTime:
                  data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(i => i.peakValleyType === item)
                    ?.startTime ?? null,
                endTime:
                  formatEndtime(
                    data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(i => i.peakValleyType === item)
                      ?.endTime
                  ) ?? null,
                power:
                  data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(i => i.peakValleyType === item)
                    ?.power ?? null,
              };
            }),
          });
        } else {
          form.setFieldValue('chargingAndDischargingPeriodDetail', undefined);
        }
      } else {
        if (!isNil(timePeriodGranularity)) {
          if (chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
            const formatPrice = getTimeString({ price: price ?? [], timePeriodGranularity });
            form.setFieldsValue({
              chargingAndDischargingPeriodDetail: (formatPrice ?? []).map(item => {
                return {
                  key: Math.random(),
                  type: item.type,
                  startTime: item.startTime,
                  endTime: formatEndtime(item.endTime),
                };
              }),
            });
          } else {
            const formatPrice = getTimeString({ price: price ?? [], timePeriodGranularity });
            const chargingAndDischargingPeriodDetailFormTemp = form.getFieldValue('chargingAndDischargingPeriodDetail');

            form.setFieldsValue({
              chargingAndDischargingPeriodDetail: (formatPrice ?? []).map(item => {
                const findPower = data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(
                  i => i.startTime?.slice(0, 5)! + i.endTime?.slice(0, 5) === item.startTime + item.endTime
                );
                const findOriginPower = chargingAndDischargingPeriodDetailFormTemp?.find(
                  (i: any) => i.startTime?.slice(0, 5)! + i.endTime?.slice(0, 5) === item.startTime + item.endTime
                );
                return {
                  key: Math.random(),
                  type: item.type,
                  startTime: item.startTime,
                  endTime: formatEndtime(item.endTime),
                  power: item.type === ActionType.StandBy ? 0 : findOriginPower?.power ?? findPower?.power,
                };
              }),
            });
          }
        } else {
          form.setFieldValue('chargingAndDischargingPeriodDetail', undefined);
        }
      }
    }
  }, [
    periodTypes,
    chargingAndDischargingPower,
    plainAndValleyTypeList,
    peakAndSharpTypeList,
    price,
    timePeriodGranularity,
  ]);

  useEffect(() => {
    if (data && form) {
      // const price = new Map<
      //   Exclude<keyof CreatePriceFormProps, 'timePeriodGranularity' | 'price'>,
      //   number | undefined
      // >();
      // data.additionalFields?.chargingAndDischargingPeriodDetail?.forEach(i => {
      //   price.set(
      //     ActionTypeTypeDisplay[i.type as ActionType] as Exclude<
      //       keyof CreatePriceFormProps,
      //       'timePeriodGranularity' | 'price'
      //     >,
      //     i.power
      //   );
      // });
      const priceData = getPriceTimeIndex(data);
      form.setFieldsValue({
        templateName: data.templateName,
        templateType: data.templateType,
        periodTypes: data.additionalFields?.periodTypes,
        epTemplateId: data.additionalFields?.epTemplateId,
        epTemplateName: data.additionalFields?.epTemplateName,
        timePeriodGranularity: data.additionalFields?.timePeriodGranularity,
        chargingAndDischargingPower: data.additionalFields?.chargingAndDischargingPower,
        peakAndSharpTypeList: data.additionalFields?.peakAndSharpTypeList,
        plainAndValleyTypeList: data.additionalFields?.plainAndValleyTypeList,
        chargingAndDischargingPeriodDetail: data.additionalFields?.chargingAndDischargingPeriodDetail?.map(item => {
          return {
            key: Math.random(),
            ...item,
            endTime: formatEndtime(item.endTime),
          };
        }),
        price: priceData,
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (displayType !== undefined) {
      form.setFieldsValue({
        price: [],
      });
    }
  }, [displayType, form]);

  const onFinish = (values: any) => {
    setSubmitting(true);
    if (id) {
      const editValue = {
        id: Number(id),
        templateName: values.templateName,
        templateType: values.templateType,
        neoBsaId: bsaId ? Number(bsaId) : undefined,
        additionalFields: {
          periodTypes: values.periodTypes,
          epTemplateId: values.epTemplateId,
          peakAndSharpTypeList: values?.peakAndSharpTypeList,
          plainAndValleyTypeList: values?.plainAndValleyTypeList,
          timePeriodGranularity: !isNil(values.timePeriodGranularity)
            ? values.timePeriodGranularity
            : values.epTemplateId
            ? elecTempList?.find(i => i.epTemplateId === values.epTemplateId)?.timePeriodGranularity
            : undefined,
          chargingAndDischargingPower: values.chargingAndDischargingPower,
          chargingAndDischargingPeriodDetail: (values.chargingAndDischargingPeriodDetail ?? []).map((i: any) => ({
            ...i,
            endTime: formatSubmitTime(i?.endTime),
          })),
        },
      };
      apiV2BsaStrategyTemplateEditPost({ ...editValue })
        .then(res => {
          if (res) {
            goList();
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      const createValue = {
        templateName: values.templateName,
        templateType: values.templateType,
        neoBsaId: bsaId ? Number(bsaId) : undefined,
        additionalFields: {
          periodTypes: values.periodTypes,
          epTemplateId: values.epTemplateId,
          peakAndSharpTypeList: values?.peakAndSharpTypeList,
          plainAndValleyTypeList: values?.plainAndValleyTypeList,
          timePeriodGranularity: !isNil(values.timePeriodGranularity)
            ? values.timePeriodGranularity
            : values.epTemplateId
            ? elecTempList?.find(i => i.epTemplateId === values.epTemplateId)?.timePeriodGranularity
            : undefined,
          chargingAndDischargingPower: values.chargingAndDischargingPower,
          chargingAndDischargingPeriodDetail: (values.chargingAndDischargingPeriodDetail ?? []).map((i: any) => ({
            ...i,
            endTime: formatSubmitTime(i?.endTime),
          })),
        },
      };
      apiV2BsaStrategyTemplateAddPost({ ...createValue })
        .then(res => {
          if (res) {
            goList();
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const goList = () => {
    navigate(`/energy-storage/basic/bsa/templateList?bsaId=${bsaId}`);
  };

  const onCancel = () => {
    Modal.confirm({
      title: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const { data: elecTempList } = useRequest(
    async () => {
      const res = await apiV2BsaStrategyTemplateEpDetailPost({ id: Number(bsaId) });
      return res.list;
    },
    {
      ready: !!bsaId,
      refreshDeps: [bsaId, updateState],
    }
  );

  // 默认选中第一个bsa
  useEffect(() => {
    if (elecTempList && elecTempList.length !== 0 && !id) {
      form.setFieldValue('epTemplateId', elecTempList[0].epTemplateId);
    }
  }, [elecTempList, id]);

  const elecTempOptions = useMemo(() => {
    if (elecTempList && elecTempList.length !== 0) {
      return elecTempList.map(i => ({ label: i.epTemplateName, value: i.epTemplateId }));
    }
  }, [elecTempList]);

  const columns = useMemo(() => {
    if (!isNil(periodTypes) && !isNil(chargingAndDischargingPower)) {
      if (periodTypes === PVPeriods.FollowEpriceTemplate) {
        if (chargingAndDischargingPower === ChargingAndDischargingPower.Custom) {
          return columnsWithFollowPrice(bsaConfigData);
        }
      } else {
        if (chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
          return columnsWithCustomFollowMax;
        } else {
          return columnsWithCustomCustom(bsaConfigData);
        }
      }
    }
    return [];
  }, [periodTypes, chargingAndDischargingPower, bsaConfigData]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={id ? '编辑策略模板' : '新建策略模板'}></FormTitle>
      <SubContent>
        <Spin spinning={tableLoading}>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="templateName"
              label="策略模板名称"
              style={{ width: '50%' }}
              rules={[
                { required: true, message: '请输入策略模板名称' },
                { max: 50, message: '最多输入五十个字' },
              ]}
            >
              <Input placeholder="请输入策略模板名称" />
            </Form.Item>
            <Form.Item
              name="templateType"
              label="策略模板类型"
              initialValue={StrategyTemplateType.PSVF}
              rules={[{ required: true, message: '请选择策略模板类型' }]}
            >
              <Radio.Group>
                <Radio value={StrategyTemplateType.PSVF}>
                  {StrategyTemplateTypeDisplay[StrategyTemplateType.PSVF]}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="periodTypes"
              label="峰谷时段"
              initialValue={PVPeriods.FollowEpriceTemplate}
              rules={[{ required: true, message: '请选择峰谷时段' }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={PVPeriods.FollowEpriceTemplate}>
                  {PVPeriodsDisplay[PVPeriods.FollowEpriceTemplate]}
                </Radio.Button>
                <Radio.Button value={PVPeriods.CustomSettings}>
                  {PVPeriodsDisplay[PVPeriods.CustomSettings]}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {periodTypes === PVPeriods.CustomSettings ? (
              <Form.Item
                name="timePeriodGranularity"
                label="时段颗粒度"
                rules={[{ required: true, message: '请选择时段颗粒度' }]}
              >
                <Radio.Group
                  onChange={e => {
                    setDisplayType(e.target.value);
                  }}
                >
                  <Radio value={PricedisplayType.HALF}>{PricedisplayTypeDisplay[PricedisplayType.HALF]}</Radio>
                  <Radio value={PricedisplayType.HOUR}>{PricedisplayTypeDisplay[PricedisplayType.HOUR]}</Radio>
                </Radio.Group>
              </Form.Item>
            ) : (
              <div style={{ display: 'flex' }}>
                <Form.Item
                  name="epTemplateId"
                  label="电价模板"
                  style={{ width: '50%' }}
                  rules={[{ required: true, message: '请选择电价模板' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    options={elecTempOptions}
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
                {epTemplateId && (
                  <Space size={16} style={{ marginBottom: 24 }}>
                    <Button
                      icon={<FileSearchOutlined />}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      type="link"
                      onClick={() =>
                        window.open(
                          `${window.IOTPLATFORMORIGIN}/operation/energy/contract/electric-rate/detail/${epTemplateId}`,
                          '_blank'
                        )
                      }
                    >
                      查看
                    </Button>

                    <Button
                      icon={<SyncOutlined />}
                      onClick={() => updateFn()}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      type="link"
                    >
                      刷新
                    </Button>
                    <Button
                      icon={<RightCircleOutlined />}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      type="link"
                      onClick={() =>
                        window.open(
                          `/energy/scheduling/topology?dispatchingGroup=${
                            elecTempList?.find(i => i.epTemplateId === epTemplateId)?.dispatchGroupId
                          }&topologyId=${
                            elecTempList?.find(i => i.epTemplateId === epTemplateId)?.dispatchUetStructId
                          }`,
                          '_blank'
                        )
                      }
                    >
                      前往关联电价模板
                    </Button>
                  </Space>
                )}
              </div>
            )}

            <Form.Item
              name="chargingAndDischargingPower"
              label={
                <>
                  充放电功率
                  <Tooltip title="跟随电价模板进行充放电，取控制配置中的最大充电功率和最大放电功率。">
                    <InfoCircleOutlined style={{ marginLeft: 4, marginRight: 4, color: '#FAAD14' }} />
                  </Tooltip>
                </>
              }
              initialValue={ChargingAndDischargingPower.FollowMaxPower}
              rules={[{ required: true, message: '请选择充放电功率' }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={ChargingAndDischargingPower.FollowMaxPower}>
                  {ChargingAndDischargingPowerplay[ChargingAndDischargingPower.FollowMaxPower]}
                </Radio.Button>
                <Radio.Button value={ChargingAndDischargingPower.Custom}>
                  {ChargingAndDischargingPowerplay[ChargingAndDischargingPower.Custom]}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {periodTypes === PVPeriods.CustomSettings ? (
              <ChargeDisChangeTime />
            ) : (
              <>
                <Form.Item
                  name="plainAndValleyTypeList"
                  label="充电时段"
                  initialValue={[PeaksAndValleysType.Valley]}
                  rules={[{ required: true, message: '请选择充电时段' }]}
                >
                  <Checkbox.Group>
                    <Checkbox value={PeaksAndValleysType.Valley}>
                      {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Valley]}
                    </Checkbox>
                    <Checkbox value={PeaksAndValleysType.Flat}>
                      {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Flat]}
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>

                <Form.Item
                  name="peakAndSharpTypeList"
                  label="放电时段"
                  initialValue={[PeaksAndValleysType.Peak]}
                  rules={[{ required: true, message: '请选择放电时段' }]}
                >
                  <Checkbox.Group>
                    <Checkbox value={PeaksAndValleysType.Peak}>
                      {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Peak]}
                    </Checkbox>
                    <Checkbox value={PeaksAndValleysType.Sharp}>
                      {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Sharp]}
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}

            {chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower &&
            periodTypes === PVPeriods.FollowEpriceTemplate ? (
              <></>
            ) : (
              <Col span={24}>
                <Form.Item
                  name="chargingAndDischargingPeriodDetail"
                  style={{ marginBottom: 0 }}
                  valuePropName="dataSource"
                >
                  <Table rowKey="key" columns={columns} pagination={false} />
                </Form.Item>
              </Col>
            )}

            <Space size={8} className="sticky-footer" style={{ zIndex: 99, paddingLeft: 0 }}>
              <Button type="primary" htmlType="submit" loading={submitting}>
                保存
              </Button>
              <Button className={styles.button} onClick={onCancel}>
                返回
              </Button>
            </Space>
          </Form>
        </Spin>
      </SubContent>
    </Wrapper>
  );
};

export default StrategyTemplateEdit;
