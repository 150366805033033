import ShowInput from '@/components/ShowInput';
import { EllipsisSpan, Form, InputNumber } from '@maxtropy/components';
import { V2BsaControlConfigPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { ColumnsType } from 'antd/es/table';
import { ActionType, ActionTypeTypeDisplay } from './type';

export interface PowerListItem {
  // id: Key;
  type: number;
  startTime: number;
  endTime: number;
  power?: number;
}

export const columnsWithFollowPrice = (
  bsaConfigData?: V2BsaControlConfigPostResponse,
  isDetail?: boolean
): ColumnsType<PowerListItem> => {
  return [
    {
      title: '动作',
      dataIndex: 'type',
      render(value, _) {
        return <EllipsisSpan value={ActionTypeTypeDisplay[value as ActionType]}></EllipsisSpan>;
      },
    },
    {
      title: '时间段',
      render(_, _record, index) {
        return (
          <Form.Item name={['chargingAndDischargingPeriodDetail', index, 'time']} style={{ marginBottom: 0 }}>
            <ShowInput />
          </Form.Item>
        );
      },
    },
    {
      title: '功率(kW)',
      dataIndex: 'power',
      // editable: true,
      render: (value, _record, index) => {
        if (isDetail) {
          return <EllipsisSpan value={value}></EllipsisSpan>;
        } else {
          return (
            <Form.Item noStyle dependencies={['chargingAndDischargingPeriodDetail', index, 'type']}>
              {({ getFieldValue }) => {
                const configType = getFieldValue(['chargingAndDischargingPeriodDetail', index, 'type']);
                if (configType === ActionType.Charge) {
                  return (
                    <Form.Item
                      noStyle
                      name={['chargingAndDischargingPeriodDetail', index, 'power']}
                      rules={[{ required: true, message: '请输入功率' }]}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber style={{ width: '50%' }} min={1} max={bsaConfigData?.maxChargePower ?? 99999999} />
                    </Form.Item>
                  );
                } else if (configType === ActionType.DisCharge) {
                  return (
                    <Form.Item
                      noStyle
                      name={['chargingAndDischargingPeriodDetail', index, 'power']}
                      rules={[{ required: true, message: '请输入功率' }]}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber
                        style={{ width: '50%' }}
                        min={1}
                        max={bsaConfigData?.maxDischargePower ?? 99999999}
                      />
                    </Form.Item>
                  );
                } else {
                  return (
                    <Form.Item
                      noStyle
                      name={['chargingAndDischargingPeriodDetail', index, 'power']}
                      rules={[{ required: true, message: '请输入功率' }]}
                      initialValue={0}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber style={{ width: '50%' }} disabled />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          );
        }
      },
    },
  ];
};

export const columnsWithCustomFollowMax: ColumnsType<PowerListItem> = [
  {
    title: '动作',
    dataIndex: 'type',
    render(value, _) {
      return <EllipsisSpan value={ActionTypeTypeDisplay[value as ActionType]}></EllipsisSpan>;
    },
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
];

export const columnsWithCustomCustom = (
  bsaConfigData?: V2BsaControlConfigPostResponse,
  isDetail?: boolean
): ColumnsType<PowerListItem> => [
  {
    title: '动作',
    dataIndex: 'type',
    render(value, _) {
      return <EllipsisSpan value={ActionTypeTypeDisplay[value as ActionType]}></EllipsisSpan>;
    },
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
  {
    title: '功率(kW)',
    dataIndex: 'power',
    render: (value, _record, index) => {
      if (isDetail) {
        return <EllipsisSpan value={value}></EllipsisSpan>;
      } else {
        const configType = _record.type;
        if (configType === ActionType.Charge) {
          return (
            <Form.Item
              name={['chargingAndDischargingPeriodDetail', index, 'power']}
              rules={[{ required: true, message: '请输入功率' }]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: '50%' }} min={1} max={bsaConfigData?.maxChargePower ?? 99999999} />
            </Form.Item>
          );
        } else if (configType === ActionType.DisCharge) {
          return (
            <Form.Item
              name={['chargingAndDischargingPeriodDetail', index, 'power']}
              rules={[{ required: true, message: '请输入功率' }]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: '50%' }} min={1} max={bsaConfigData?.maxDischargePower ?? 99999999} />
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              name={['chargingAndDischargingPeriodDetail', index, 'power']}
              rules={[{ required: true, message: '请输入功率' }]}
              initialValue={0}
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: '50%' }} disabled />
            </Form.Item>
          );
        }
      }
    },
  },
];
