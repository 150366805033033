import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, Input, Key, Modal, Select, ShowInput } from '@maxtropy/components';
import Title from '../components/Title';
import Footer from '../components/Footer';
import { useRequest } from 'ahooks';
import { isEmpty, isNil } from 'lodash-es';
import { iconArr } from '@/pages/SchedulingTopology/utils/utils';
import {
  apiV2DispatchUetStructOtherCreatePost,
  apiV2DispatchUetStructOtherDeletePost,
  apiV2DispatchUetStructOtherListPost,
  apiV2DispatchUetStructOtherUpdatePost,
  apiV2DispatchUetStructOtherVerticalCreatePost,
  apiV2DispatchUetStructOtherVerticalDeletePost,
  apiV2DispatchUetStructOtherVerticalListPost,
  apiV2DispatchUetStructOtherVerticalPublishGetPost,
  apiV2DispatchUetStructOtherVerticalUpdatePost,
  apiV2DispatchUetStructPublishGridAccessPointGetPost,
  V2DispatchUetStructDevicePagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import classNames from 'classnames/bind';
import AddMetersModal from './AddMetersModal';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';
import { allInfoProps, NodeType, TreeNode } from '@/pages/SchedulingTopology/type';
import { NodeType as NodeTypeClass } from '../types';

const cx = classNames.bind(styles);

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  type?: NodeType;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}

const { Item } = Form;

const OtherVertical: FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  type,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();

  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const icon = Form.useWatch('icon', form);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectRows, setSelectRows] = useState<V2DispatchUetStructDevicePagePostResponse['list']>([]);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  useEffect(() => {
    if (isEmpty(selectRows)) return;
    const { code, name, id } = selectRows![0];
    form.setFieldsValue({
      code,
      deviceName: name,
      deviceId: id,
    });
  }, [form, selectRows]);

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructOtherVerticalPublishGetPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructOtherVerticalListPost({ id });
      }
      if (detail) {
        setSelectedRowKeys([detail.deviceId!]);
        form.setFieldsValue({
          ...detail,
        });
        setIconVisible(true);
        setTitle(detail.name);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, type);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '其他样式-竖向'}
            delMethod={apiV2DispatchUetStructOtherVerticalDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.OTHER}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructOtherVerticalCreatePost}
            updateMethod={apiV2DispatchUetStructOtherVerticalUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="name" label="节点名称" rules={[{ required: true, min: 1, max: 50 }]}>
              <Input placeholder="请输入" disabled={isDetail} allowClear />
            </Item>

            <Item label="设备编号" name="code">
              <span
                className={cx('select_btn', isDetail ? 'disable_btn' : '')}
                onClick={() => {
                  if (isDetail) return;
                  setAddModalVisible(true);
                }}
              >
                {selectRows?.[0]?.code ?? detail?.code ?? '设备选择'}
              </span>
            </Item>

            <Item label="设备名称" name="deviceName">
              <ShowInput />
            </Item>

            <Item name="icon" label="图标选择" rules={[{ required: true, message: '请选择图标选择' }]}>
              <Select placeholder="请选择" options={iconArr} disabled={isDetail} allowClear />
            </Item>

            <Item name="id" hidden />
            <Item name="deviceId" hidden />
            <Item name="type" initialValue={1} hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div
                      className={styles.icon}
                      style={{ backgroundImage: `url(${iconArr.find(i => i.value === icon)?.img})` }}
                    />
                    <Item noStyle dependencies={['name']}>
                      {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <AddMetersModal
        topologyId={topologyId}
        gridAccessPointId={gridAccessPointId}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={(rowKeys: Key[]) => setSelectedRowKeys(rowKeys)}
        setSelectRows={setSelectRows}
        onCancel={() => setAddModalVisible(false)}
        open={addModalVisible}
      />
    </>
  );
};

export default OtherVertical;
