import { Graph, Node } from '@antv/x6';
import { Key } from 'react';

export enum NodeType {
  GRID_SIDE = 'GRID_SIDE', // 电网侧
  GRID_ACCESS_POINT = 'GRID_ACCESS_POINT', // 电网接入点
  TRANSFORMER = 'TRANSFORMER', // 变压器
  BUS_BAR = 'BUS_BAR', // 母线
  PVA = 'PVA', // 光伏站
  BSA = 'BSA', // 储能站
  LOAD = 'LOAD', // 负载
  GATEWAY = 'GATEWAY', // 网关
  CHARGING_STATION = 'CHARGING_STATION', // 充电站
  BACKUP_POWER = 'BACKUP_POWER', // 备用电源
  ANTI_BACKFLOW_DEVICE = 'ANTI_BACKFLOW_DEVICE', // 防逆流装置
  GRID_ACCESS_BILLING_POINT = 'GRID_ACCESS_BILLING_POINT', // 总进线计费点
  PVA_BILLING_POINT = 'PVA_BILLING_POINT', // 光伏计费点
  CHARGING_STATION_BILLING_POINT = 'CHARGING_STATION_BILLING_POINT', // 充电站计费点
  LOAD_BILLING_POINT = 'LOAD_BILLING_POINT', // 负载计费点
  BSA_BILLING_POINT = 'BSA_BILLING_POINT', // 储能计费点
  ANTI_OVERCAPACITY_POINT = 'ANTI_OVERCAPACITY_POINT', // 防超容点
  FCS_CONTROLLER = 'FCS_CONTROLLER', // 就地控制器
  BSA_GRID_CONNECTION_BILLING_POINT = 'BSA_GRID_CONNECTION_BILLING_POINT', // 储能并网点
  UNIVERSAL_JOINT_OPENING = 'UNIVERSAL_JOINT_OPENING', // 通用开关节点
  OTHER = 'OTHER', // 其他
  OTHER_VERTICAL = 'OTHER_VERTICAL', // 其他样式-竖向
  OTHER_TRANSVERSE = 'OTHER_TRANSVERSE', // 其他样式-横向
  DC = 'DC', // 交直流转换器
  DCAC = 'DCAC', // 直流转换器
}

export const NodeTypeDisplay = {
  [NodeType.GRID_SIDE]: '电网侧',
  [NodeType.GRID_ACCESS_POINT]: '电网接入点',
  [NodeType.TRANSFORMER]: '变压器',
  [NodeType.BUS_BAR]: '母线',
  [NodeType.PVA]: '光伏站',
  [NodeType.BSA]: '储能站',
  // [NodeType.METERS]: '计量电表',
  [NodeType.LOAD]: '负载',
  [NodeType.GATEWAY]: '网关',
  [NodeType.CHARGING_STATION]: '充电站',
  [NodeType.BACKUP_POWER]: '备用电源',
  [NodeType.ANTI_BACKFLOW_DEVICE]: '防逆流装置',

  [NodeType.GRID_ACCESS_BILLING_POINT]: '总进线计费点',
  [NodeType.PVA_BILLING_POINT]: '光伏计费点',
  [NodeType.CHARGING_STATION_BILLING_POINT]: '充电站计费点',
  [NodeType.LOAD_BILLING_POINT]: '负载计费点',
  [NodeType.BSA_BILLING_POINT]: '储能计费点',
  [NodeType.ANTI_OVERCAPACITY_POINT]: '防超容点',
  [NodeType.FCS_CONTROLLER]: '就地控制器',
  [NodeType.BSA_GRID_CONNECTION_BILLING_POINT]: '储能并网点',
  [NodeType.UNIVERSAL_JOINT_OPENING]: '通用开关',

  [NodeType.OTHER]: '自定义',
  [NodeType.OTHER_VERTICAL]: '自定义样式-竖向',
  [NodeType.OTHER_TRANSVERSE]: '自定义样式-横向',

  [NodeType.DC]: '交直流转换器',
  [NodeType.DCAC]: '直流转换器',
};

export enum NodeTypeClass {
  SOURCE = 'SOURCE', //源节点
  LOAD = 'LOAD', //负荷节点
  STORAGE = 'STORAGE', //储节点
  TRANSFORM = 'TRANSFORM', //转换节点
  DISTRIBUTIONC = 'DISTRIBUTION', //分配节点
  SWITCH = 'SWITCH', //开关节点
  NETWORK = 'NETWORK', //网络节点
  OTHER = 'OTHER', // 其他
}

export const NodeTypeClassDisplay = {
  [NodeTypeClass.SOURCE]: '源节点',
  [NodeTypeClass.LOAD]: '负荷节点',
  [NodeTypeClass.STORAGE]: '储节点',
  [NodeTypeClass.TRANSFORM]: '转换节点',
  [NodeTypeClass.DISTRIBUTIONC]: '分配节点',
  [NodeTypeClass.SWITCH]: '开关节点',
  [NodeTypeClass.NETWORK]: '网络节点',
  [NodeTypeClass.OTHER]: '自定义节点',
};

// 树结构数据类型
export interface TreeNode {
  id: number | string;
  element: NodeType;
  index?: number;
  parentId?: number;
  children: TreeNode[];
}

// 提交树结构的类型
export interface TreeNodeSubmit {
  id: number;
  element: NodeType;
  parentId?: number;
  children: TreeNodeSubmit[];
}

// Node节点的数据Data
export interface NodeData {
  id: Key;
  element: NodeType;
  parentId: number | null;
  name: string;
  value?: number;
  busBarNodes?: number;
  busbarNeedExtendsMap?: busbarNeedExtendsMapArr | busbarNeedExtendsMapLeftOrRight;
  // busbarNeedExtendsMap?: any;
}

export interface busbarNeedExtendsMapArr {
  grade: number;
  busbarLength: number;
  circleArr: BusBarCircleNumber[];
}
export interface busbarNeedExtendsMapLeftOrRight {
  leftOrRight: string;
}

// 网关列表
export interface GateWayList {
  id: number;
  element: NodeType;
}

// fcs列表
export interface FcsList {
  id: number;
  element: NodeType;
}

// 开关所选设备
export interface SwitchSelectDeviceList {
  id: Key;
  element: NodeType;
  deviceId: number;
}

// 创建节点时所需的类型
export interface allInfoProps {
  upperOrDown: string; // 创建的方位，上下还是左右
  allNodeData: TreeNode[]; // 树结构数据
  node: Node<Node.Properties>;
  graph: Graph;
  addNodeType: NodeType; // 创建节点的类型
  gatewayList: GateWayList[];
  fcsList: FcsList[];
  circleIndex?: number; // 母线第几个圈圈上新增
  circleArr?: BusBarCircleNumber[]; // 共几个圈圈
}

// 下拉选择类型
export interface nodeElementLinkTree {
  key: string;
  label: string;
  children?: nodeElementLinkTree[];
}

// 节点排序类型
export interface NodesTypeSort {
  key: string;
  label: string;
  childern?: NodesTypeSort[];
}

// 母线上需要延长的节点与index
export interface BusBarNodeAndIndex {
  findParentItem: TreeNode;
  findParentIndex: number;
}

// 母线上面点
export interface BusBarCircleNumber {
  circleNumber: number;
  left: number;
  bLinePosition: number;
}
