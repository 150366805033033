import { Edge, Graph, Node } from '@antv/x6';
import {
  apiV2DispatchUetStructAntiBackflowDeletePost,
  apiV2DispatchUetStructBackupPowerDeletePost,
  apiV2DispatchUetStructBsaDeletePost,
  apiV2DispatchUetStructBusbarDeletePost,
  apiV2DispatchUetStructChargingStationDeletePost,
  apiV2DispatchUetStructDelElementsPost,
  apiV2DispatchUetStructFcsDeletePost,
  apiV2DispatchUetStructGatewayDeletePost,
  apiV2DispatchUetStructGridAccessPointDeletePost,
  apiV2DispatchUetStructLoadDeletePost,
  apiV2DispatchUetStructMeterDeletePost,
  apiV2DispatchUetStructOtherDeletePost,
  apiV2DispatchUetStructPvDeletePost,
  apiV2DispatchUetStructTransformerDeletePost,
  apiV2DispatchUetStructUniversalJoinOpenDeletePost,
  V2DispatchUetStructGetTreePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { MessageInstance } from 'antd/es/message/interface';
import { cloneDeep, isNil } from 'lodash-es';
import {
  BusBarCircleNumber,
  BusBarNodeAndIndex,
  FcsList,
  GateWayList,
  NodeData,
  NodeType,
  TreeNode,
  busbarNeedExtendsMapArr,
  busbarNeedExtendsMapLeftOrRight,
} from '../type';
import styles from '../index.module.scss';
import {
  findAllTopNodeByCurrentNode,
  findAllTopbusBar,
  findItemSideMax,
  findTopBusBar,
  findTopDataSplitByBusBar,
  findTopDataSplitWithBusBar,
  loopTreeTofindId,
  reduceArr,
  updateChildrenPId,
} from './utils';
import { Key } from 'react';
import { removeNode } from './draw';

export const EACH_BUSBAR_LENGTH = 280;
export const ADDCIRCLE_RADIUS = 8;

// 初始化画图
export function initScTopoGraph(dom: HTMLElement): Graph {
  return new Graph({
    container: dom,
    panning: true,
    mousewheel: true,
    grid: {
      size: 4,
      visible: true,
      type: 'dot',
      args: {
        color: '#ffffff35', // 网点颜色
        thickness: 1, // 网点大小
      },
    },
    interacting: function (cellView) {
      if (!cellView.cell.getData()?.disableMove) {
        return { nodeMovable: false };
      }
      return true;
    },
  });
}

// 获取当前节点的x，y，width，height
export function getNodePositionAndSize(node: Node) {
  const x = node.getPosition().x ?? 0;
  const y = node.getPosition().y ?? 0;
  const height = node.getSize().height ?? 0;
  const width = node.getSize().width ?? 0;
  return { x, y, height, width };
}

// 判断下方节点是否有上加号
export function hasTopAddNode(addNodeType: NodeType): boolean {
  return (
    addNodeType === NodeType.PVA ||
    addNodeType === NodeType.BSA ||
    addNodeType === NodeType.TRANSFORMER ||
    addNodeType === NodeType.CHARGING_STATION ||
    addNodeType === NodeType.LOAD ||
    addNodeType === NodeType.BACKUP_POWER ||
    addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
    addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
    isMeters(addNodeType) ||
    addNodeType === NodeType.OTHER_TRANSVERSE ||
    addNodeType === NodeType.OTHER_VERTICAL
  );
}

// 判断上节点是否有下加号
export function hasBottomAddNode(addNodeType: NodeType): boolean {
  return (
    addNodeType === NodeType.GRID_ACCESS_POINT ||
    addNodeType === NodeType.TRANSFORMER ||
    addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
    addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
    isMeters(addNodeType) ||
    addNodeType === NodeType.OTHER_TRANSVERSE ||
    addNodeType === NodeType.OTHER_VERTICAL
  );
}

// 判断是否是电表
export function isMeters(type: NodeType): boolean {
  return (
    type === NodeType.PVA_BILLING_POINT ||
    type === NodeType.ANTI_OVERCAPACITY_POINT ||
    type === NodeType.BSA_BILLING_POINT ||
    type === NodeType.CHARGING_STATION_BILLING_POINT ||
    type === NodeType.GRID_ACCESS_BILLING_POINT ||
    type === NodeType.LOAD_BILLING_POINT ||
    type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT
  );
}

// port groups封装
export const portGroups = {
  groups: {
    group1: {
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          // magnet: true,
          width: 10,
          height: 10,
          stroke: '#FFCB47',
          fill: '#FFCB47',
          strokeWidth: 2,
        },
      },
      // 文档：https://x6.antv.vision/zh/docs/api/registry/port-layout#absolute
      position: {
        name: 'absolute',
      },
    },
  },
};

// 向下新增网关或者fcs
export function addGatewayOrFcsNodeData(graph: Graph, x: number, y: number, addNodeType: NodeType, values: any) {
  graph.addNode({
    shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
    x: x + 120,
    y: y,
    ports: portGroups,
    data: {
      isShowAllAddDelBtn: true,
      disableMove: true,
      id: values.id,
      element: addNodeType,
      value: {
        id: values?.id,
        edgeGatewayId: addNodeType === NodeType.GATEWAY ? values?.edgeGatewayId : values?.fcsInfo?.edgeGatewayId,
        name: addNodeType === NodeType.GATEWAY ? values?.name : values?.fcsInfo?.name,
        serialNumber: addNodeType === NodeType.GATEWAY ? values?.serialNumber : values?.fcsInfo?.serialNumber,
      },
    },
  });
}

// 向下新增其他得到x,y
export function getDownAddNodePosition(
  currentNodeX: number,
  currentNodeY: number,
  currentNodeHeight: number,
  currentNodeWidth: number,
  currentNodeElement: NodeType,
  addNodeType: NodeType
) {
  let x: number = 0;
  const y = currentNodeY + currentNodeHeight;
  if (
    isMeters(currentNodeElement) ||
    currentNodeElement === NodeType.ANTI_BACKFLOW_DEVICE ||
    currentNodeElement === NodeType.UNIVERSAL_JOINT_OPENING ||
    currentNodeElement === NodeType.OTHER_TRANSVERSE
  ) {
    if (addNodeType === NodeType.BUS_BAR) {
      x = currentNodeX - 140 + 8;
    } else if (
      isMeters(addNodeType) ||
      addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
      addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
      addNodeType === NodeType.OTHER_TRANSVERSE
    ) {
      x = currentNodeX;
    } else {
      x = currentNodeX - 80 + 8;
    }
  } else {
    if (addNodeType === NodeType.BUS_BAR) {
      x = currentNodeX - 60;
    } else if (
      isMeters(addNodeType) ||
      addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
      addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
      addNodeType === NodeType.OTHER_TRANSVERSE
    ) {
      x = currentNodeX + currentNodeWidth / 2 - 8;
    } else {
      x = currentNodeX;
    }
  }
  return { x, y };
}

// (向下新增)如果创建的是bsa，那么同步更新其他bsa
export function syncAllBsaNodes(graph: Graph, nodeDataValues: any) {
  const addedAllNodes = graph.getNodes();
  const findNeedEditAllBsaNode = addedAllNodes.filter(
    item => item.getData().values?.bsaId === nodeDataValues.bsaId && item.getData().element === NodeType.BSA
  );
  (findNeedEditAllBsaNode ?? []).forEach(item => {
    const itemData = item.getData();
    item.updateData({
      ...itemData,
      value: {
        ...itemData.value,
        controlList: nodeDataValues.controlList,
      },
    });
  });
}

// 通过id和element查找节点Node
export function findNodeByIdAndElement(graph: Graph, id: Key, element: NodeType) {
  const addedAllNodes = graph.getNodes();
  const findNode = addedAllNodes.find(item => item.getData().id === id && item.getData().element === element);
  if (findNode) {
    return findNode;
  }
}

// 通过数组查找节点
export function findAllPNodes(graph: Graph, pids: TreeNode[]) {
  const addedAllNodes = graph.getNodes();
  let nodeArr: Node<Node.Properties>[] = [];
  pids.forEach(i => {
    const findNode = addedAllNodes.find(item => item.getData().id === i.id && item.getData().element === i.element);
    if (findNode) {
      nodeArr.push(findNode);
    }
  });
  return nodeArr;
}

// 获取子节点
export function getChildrenIds(sourceData: TreeNode[]) {
  let ids: { id: number; element: NodeType }[] = [];
  const loop = (data: TreeNode[]) =>
    data.forEach(item => {
      ids.push({
        id: item.id as number,
        element: item.element,
      });
      if (item.children && item.children.length > 0) {
        loop(item.children);
      }
    });
  loop(sourceData);
  return ids;
}

// 改变Y坐标
export function changeYPosition(graph: Graph, ids: { id: number; element: NodeType }[], needAddHeight: number) {
  const addedAllNodes = graph.getNodes();
  let nodes: Node<Node.Properties>[] = [];
  for (let i = 0; i < ids.length; i++) {
    const findNode = addedAllNodes.find(
      item => item.getData().id === ids[i].id && item.getData().element === ids[i].element
    );
    findNode && nodes.push(findNode);
  }
  nodes.forEach(item => {
    let { x, y } = item.getPosition();
    item.setPosition({ x: x, y: y + needAddHeight });
  });
}

// 改变x坐标
export function changeXPosition(graph: Graph, ids: { id: Key; element: NodeType }[], needAddWidth: number) {
  const addedAllNodes = graph.getNodes();
  let nodes: Node<Node.Properties>[] = [];
  for (let i = 0; i < ids.length; i++) {
    const findNode = addedAllNodes.find(
      item => item.getData().id === ids[i].id && item.getData().element === ids[i].element
    );
    findNode && nodes.push(findNode);
  }
  nodes.forEach(item => {
    let { x, y } = item.getPosition();
    item.setPosition({ x: x + needAddWidth, y });
  });
}

// 通过item查找父元素
export function loopTreeTofindParent(
  nodes: TreeNode[],
  currentItemId: Key,
  currentItemElement: NodeType
): TreeNode | null {
  let res: TreeNode | null = null;
  function loop(array: TreeNode[], currentItemId: Key, currentItemElement: NodeType) {
    array.forEach(item => {
      if (item.children && item.children.find(i => i.id === currentItemId && i.element === currentItemElement)) {
        res = item;
      } else {
        loop(item.children, currentItemId, currentItemElement);
      }
    });
  }
  loop(nodes, currentItemId, currentItemElement);
  return res;
}

// 通过item查找父元素与index
export function loopTreeTofindParentAndIndex(
  nodes: TreeNode[],
  currentId: Key,
  currentElement: NodeType
): BusBarNodeAndIndex | null {
  let res: BusBarNodeAndIndex | null = null;
  function loop(array: TreeNode[], currentId: Key, currentElement: NodeType) {
    array.forEach(item => {
      if (item.children && item.children.find(i => i.id === currentId && i.element === currentElement)) {
        const findIndex = item.children.findIndex(i => i.id === currentId && i.element === currentElement);
        res = {
          findParentItem: item,
          findParentIndex: findIndex,
        };
      } else {
        loop(item.children, currentId, currentElement);
      }
    });
  }
  loop(nodes, currentId, currentElement);
  return res;
}

// 通过id和element查找返回数据结构树中的某项item
export function loopResTreeTofindItem(
  nodes: V2DispatchUetStructGetTreePostResponse['trees'],
  searchId: number,
  element: NodeType
): Exclude<V2DispatchUetStructGetTreePostResponse['trees'], undefined>[number] | null {
  let res: any = null;
  function loop(array: V2DispatchUetStructGetTreePostResponse['trees']) {
    (array ?? []).forEach((item: Exclude<V2DispatchUetStructGetTreePostResponse['trees'], undefined>[number]) => {
      if (item.id === searchId && item.element === element) {
        res = item;
      }
      if (item.children) loop(item.children);
    });
  }
  loop(nodes);
  return res;
}

// 通过类型去查找树中数据（因为考虑电网接入点只有一个）
export function loopResTreeTofindItemByElement(
  nodes: V2DispatchUetStructGetTreePostResponse['trees'],
  element: NodeType,
  id: number
): Exclude<V2DispatchUetStructGetTreePostResponse['trees'], undefined>[number] | null {
  let res: any = null;
  function loop(array: any) {
    array.forEach((item: any) => {
      if (item.element === element && item.id === id) {
        res = item;
      }
      if (item.children) loop(item.children);
    });
  }
  loop(nodes);
  return res;
}

// 树形扁平化渲染图
export function treeToArray(childrenNodes: TreeNode[]): TreeNode[] {
  const res: TreeNode[] = [];
  function loop(array: TreeNode[]) {
    array.forEach(item => {
      res.push(item);
      if (item?.children) loop(item.children);
    });
  }
  loop(childrenNodes);
  return res;
}

export function deleteEleFetch(
  graph: Graph,
  allNodeData: TreeNode[],
  gateWayList: GateWayList[],
  fcsList: FcsList[],
  type: NodeType,
  messageApi: MessageInstance,
  id?: number,
  drawIndex?: number,
  topologyId?: string
): Promise<TreeNode[]> {
  return new Promise(function (resolve, reject) {
    const arrayTree = treeToArray(cloneDeep(allNodeData));
    const item = arrayTree.find(i => i.element === type && i.id === id);

    let ids;
    if (item) {
      ids = getChildrenIds([item]);
      const removeChildrenIds = ids
        ?.filter(i => i.element !== type && i.id !== id)
        .map(c => ({
          elementId: c.id,
          elementType: c.element,
        }));
      if (!(isMeters(type) || type === NodeType.ANTI_BACKFLOW_DEVICE || type === NodeType.UNIVERSAL_JOINT_OPENING || type === NodeType.OTHER_TRANSVERSE)) {
        apiV2DispatchUetStructDelElementsPost({ elements: removeChildrenIds, uetStructId: topologyId }).then(_ => {
          if (type === NodeType.GRID_ACCESS_POINT) {
            apiV2DispatchUetStructGridAccessPointDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.TRANSFORMER) {
            apiV2DispatchUetStructTransformerDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.LOAD) {
            apiV2DispatchUetStructLoadDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.PVA) {
            apiV2DispatchUetStructPvDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.BSA) {
            apiV2DispatchUetStructBsaDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.BUS_BAR) {
            apiV2DispatchUetStructBusbarDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.CHARGING_STATION) {
            apiV2DispatchUetStructChargingStationDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.BACKUP_POWER) {
            apiV2DispatchUetStructBackupPowerDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          } else if (type === NodeType.GRID_SIDE) {
            // 如果删除的是电网侧
            const allNodeDataTemp = removeNode(graph, allNodeData, type, undefined, drawIndex);
            messageApi.success('删除成功');
            resolve(allNodeDataTemp);
          } else if (type === NodeType.OTHER_VERTICAL) {
            apiV2DispatchUetStructOtherDeletePost({ id: id! }).then(_ => {
              const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
              messageApi.success('删除成功');
              resolve(allNodeDataTemp);
            });
          }
        });
      }
    }
    if (type === NodeType.ANTI_BACKFLOW_DEVICE) {
      apiV2DispatchUetStructAntiBackflowDeletePost({ id: id! }).then(_ => {
        const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
        messageApi.success('删除成功');
        resolve(allNodeDataTemp);
      });
    } else if (isMeters(type)) {
      apiV2DispatchUetStructMeterDeletePost({ id: id! }).then(_ => {
        const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
        messageApi.success('删除成功');
        resolve(allNodeDataTemp);
      });
    } else if (type === NodeType.UNIVERSAL_JOINT_OPENING) {
      apiV2DispatchUetStructUniversalJoinOpenDeletePost({ id: id! }).then(_ => {
        const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
        messageApi.success('删除成功');
        resolve(allNodeDataTemp);
      });
    } else if (type === NodeType.GATEWAY) {
      apiV2DispatchUetStructGatewayDeletePost({ id: id! }).then(_ => {
        const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex, gateWayList);
        messageApi.success('删除成功');
        resolve(allNodeDataTemp);
      });
    } else if (type === NodeType.FCS_CONTROLLER) {
      apiV2DispatchUetStructFcsDeletePost({ id: id! }).then(_ => {
        const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex, gateWayList, fcsList);
        messageApi.success('删除成功');
        resolve(allNodeDataTemp);
      });
    } else if (type === NodeType.OTHER_TRANSVERSE) {
      apiV2DispatchUetStructOtherDeletePost({ id: id! }).then(_ => {
        const allNodeDataTemp = removeNode(graph, allNodeData, type, id!, drawIndex);
        messageApi.success('删除成功');
        resolve(allNodeDataTemp);
      });
    }
  });
}

// 创建节点后数据更新
export function addedNodeValue(addNodeType: NodeType, values: any) {
  let value: any = null;
  if (addNodeType === NodeType.TRANSFORMER) {
    value = {
      id: values?.id,
      name: values?.transformerDeviceIdNameMap?.name ?? '--',
      lowerVoltageLevel: values.lowerVoltageLevel ?? '--',
      highVoltageLevel: values.highVoltageLevel ?? '--',
    };
  } else if (addNodeType === NodeType.BUS_BAR) {
    value = {
      id: values?.id,
      name: values?.name ?? '--',
      level: values?.level ?? '--',
    };
  } else if (isMeters(addNodeType)) {
    value = {
      id: values?.id,
      name: values?.deviceName ?? '--',
      ammeterType: values?.ammeterType,
    };
  } else if (addNodeType === NodeType.PVA) {
    value = {
      id: values?.id ?? '--',
      name: values?.pvStationDto?.name ?? '--',
      isPart: values.moleculeGridConnectionPoint !== values.totalGridConnectionPoint,
      rate:
        (!isNil(values.moleculeGridConnectionPoint) ? values.moleculeGridConnectionPoint.toString() : '--') +
        '/' +
        (!isNil(values.totalGridConnectionPoint) ? values.totalGridConnectionPoint.toString() : '--'),
      controlList: values.hasControlItem ? [...(values.agcDeviceVo ?? []), ...(values.pvInverterDtos ?? [])] : [],
    };
  } else if (addNodeType === NodeType.BSA) {
    const pcsList = (values.pcsList ?? []).map((item: any) => {
      return {
        ...item,
        name: item.device.name,
      };
    });
    value = {
      id: values?.id,
      name: values?.bsaSimpleVo?.name ?? '--',
      isPart: values.moleculePcsNum !== values.totalPcsNum,
      bsaId: values?.bsaSimpleVo?.id,
      pcsRate:
        (!isNil(values.moleculePcsNum) ? values.moleculePcsNum.toString() : '--') +
        '/' +
        (!isNil(values.totalPcsNum) ? values.totalPcsNum.toString() : '--'),
      // pcsList: values.hasControlItem ? values.pcsDevice : [],

      controlList: values.hasControlItem
        ? [
            ...pcsList,
            ...values.airConditionerDevice,
            ...values.fireControlDevice,
            ...values.hygrographDevice,
            ...values.smokeSensorDevice,
          ]
        : [],
    };
  } else if (addNodeType === NodeType.LOAD) {
    value = {
      id: values?.id,
      name: values.loadIdName?.name ?? '--',
      circuitBreakerList: values?.circuitBreakerList ?? [],
    };
  } else if (addNodeType === NodeType.GATEWAY) {
    value = {
      id: values?.id,
      edgeGatewayId: values.edgeGatewayId ?? '--',
      netWorkId: values?.networkId,
      name: values?.name ?? '--',
      serialNumber: values.serialNumber ?? '--',
    };
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    value = {
      id: values?.id,
      edgeGatewayId: values?.fcsInfo?.edgeGatewayId ?? '--',
      netWorkId: values?.fcsInfo?.edgeNetworkId,
      name: values?.fcsInfo.name ?? '--',
      serialNumber: values?.fcsInfo?.serialNumber ?? '--',
    };
  } else if (addNodeType === NodeType.GRID_ACCESS_POINT) {
    value = {
      id: values?.id,
      name: values?.name ?? '--',
      voltageLevel: values?.voltageLevel ?? '--',
    };
  } else if (addNodeType === NodeType.CHARGING_STATION) {
    value = {
      id: values?.id,
      name: values.chargingStation?.name ?? '--',
    };
  } else if (addNodeType === NodeType.BACKUP_POWER) {
    value = {
      id: values?.id,
      name: values.backupPower?.name ?? '--',
      controlObjName: values.hasControlItem ? values.backupPower?.relatedDeviceName ?? '--' : undefined,
    };
  } else if (addNodeType === NodeType.ANTI_BACKFLOW_DEVICE) {
    value = {
      id: values?.id,
      // name: values.deviceName,
      name: values?.deviceVo?.name,
    };
  } else if (addNodeType === NodeType.UNIVERSAL_JOINT_OPENING) {
    value = {
      id: values?.id,
      name: values?.name ?? '--',
      deviceIds: values?.deviceList?.map((item: any) => item.id) ?? [],
    }
  } else if (addNodeType === NodeType.OTHER_VERTICAL || addNodeType === NodeType.OTHER_TRANSVERSE) {
    value = {
      id: values?.id,
      name: values?.name,
      icon: values?.icon,
    };
  }
  return value;
}

// 找到所有gateway的node
export function findAllSameElementNode(addedAllNodes: Node<Node.Properties>[], nodeType: NodeType) {
  let res: Node<Node.Properties>[] = [];
  addedAllNodes.forEach(i => {
    if (i.getData().element === nodeType) {
      res.push(i);
    }
  });
  return res;
}

// 全屏
export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}

// 添加网关与bsa的链接装
export function addPortwithGateWay(
  addedAllNodes: Node<Node.Properties>[],
  graphRef: Graph,
  edgesRef: Edge<Edge.Properties>[]
) {
  const allFcsNodes = findAllSameElementNode(addedAllNodes, NodeType.FCS_CONTROLLER);
  const gatewayNodes = findAllSameElementNode(addedAllNodes, NodeType.GATEWAY);
  // 所有bsa加上链接装
  allFcsNodes.forEach(i => {
    i.addPort({
      id: 'port1',
      group: 'group1',
      attrs: {
        rect: {
          width: 10,
          height: 10,
          stroke: '#FFCB47',
          fill: '#FFCB47',
          strokeWidth: 0,
        },
      },
      // 通过 args 指定绝对位置
      args: {
        x: i.getSize().width,
        y: i.getSize().height / 2 - 5,
      },
    });
  });

  // 所有网关加上链接装
  gatewayNodes.forEach(i => {
    i.addPort({
      id: 'port1',
      group: 'group1',
      attrs: {
        rect: {
          width: 10,
          height: 10,
          stroke: '#FFCB47',
          fill: '#FFCB47',
          strokeWidth: 0,
        },
      },
      // 通过 args 指定绝对位置
      args: {
        x: i.getSize().width,
        y: i.getSize().height / 2 - 5,
      },
    });
  });

  gatewayNodes.forEach(i => {
    const findFcsNode = allFcsNodes.find(m => m.getData().value.netWorkId === i.getData().value.netWorkId);
    if (findFcsNode) {
      // 添加边
      const edges = graphRef.addEdge({
        source: {
          cell: findFcsNode,
          anchor: {
            name: 'right',
          },
          connectionPoint: 'anchor',
          // port: 'port1', // 连接桩 ID
          // x: i.getPosition().x + i.getSize().width,
          // y: i.getPosition().y + 60 + (index + 1) * 30 + 5,
        },
        target: {
          cell: i,
          // port: 'port1', // 连接桩 ID
          anchor: {
            name: 'right',
          },
          connectionPoint: 'anchor',
        },
        router: {
          name: 'manhattan',
          args: {
            startDirections: ['right'],
            endDirections: ['right'],
          },
        },
        attrs: {
          line: {
            stroke: '#FFCB47',
            strokeDasharray: 5,
            sourceMarker: null,
            targetMarker: null,
            // targetMarker: 'classic',
            class: styles.antLine,
          },
        },
      });
      edgesRef.push(edges);
    }
  });
}

// 添加节点后其余节点向右移动
export const moveNodesAffterAdd = (
  allNodeData: TreeNode[],
  allNodeDataTemp: TreeNode[],
  currentGroupId: number | string,
  maxXRef: number,
  type?: NodeType,
  graph?: Graph
) => {
  // 找到索引,这里的allNodeData是每个电网侧串
  const drawIndex = allNodeData?.findIndex(i => i.id === currentGroupId);
  allNodeData?.splice(drawIndex, 1, ...allNodeDataTemp);
  if (type !== NodeType.GATEWAY && type !== NodeType.FCS_CONTROLLER) {
    // 其余电网侧向右移动
    const moveTreeNodes = allNodeData.filter((_, index) => {
      return index > drawIndex;
    });

    const addedAllNodes = graph?.getNodes();
    const treeList = treeToArray(moveTreeNodes ?? []);
    const moveNodes = (treeList ?? []).map(i => {
      const node = addedAllNodes
        ?.filter(m => m.getData().element !== NodeType.GATEWAY && m.getData().element !== NodeType.FCS_CONTROLLER)
        .find(c => c.getData().id + c.getData().element === i.id + i.element);
      if (node) {
        return node;
      }
      return null;
    });

    const maxX = findItemSideMax([allNodeData[drawIndex]], graph);
    if (maxXRef < maxX) {
      moveNodes.forEach(i => {
        // i?.setPosition({ x: i.getPosition().x + 280, y: i.getPosition().y });
        i?.setPosition({ x: i.getPosition().x + (maxXRef === 0 ? 280 : maxX - maxXRef), y: i.getPosition().y });
      });
      maxXRef = maxX;
    }
  }
  return maxXRef;
};

// 删除节点后，其余节点向左移动
export const moveNodesAffterRemove = (
  res: TreeNode[],
  allNodeData: TreeNode[],
  graph: Graph,
  maxXRef: number,
  drawIndex?: number,
  type?: NodeType
) => {
  // allNodeData = res;

  if (type !== NodeType.GATEWAY && type !== NodeType.FCS_CONTROLLER) {
    // 其余电网侧（右边的）向左移动
    const moveTreeNodes = allNodeData.filter((item, index) => {
      return index > drawIndex!;
    });
    const addedAllNodes = graph.getNodes();
    const treeList = treeToArray(moveTreeNodes ?? []);
    const moveNodes = (treeList ?? []).map(i => {
      const node = addedAllNodes
        .filter(m => m.getData().element !== NodeType.GATEWAY && m.getData().element !== NodeType.FCS_CONTROLLER)
        .find(c => c.getData().id + c.getData().element === i.id + i.element);
      if (node) {
        return node;
      }
      return null;
    });
    const maxX = findItemSideMax([allNodeData[drawIndex!]], graph);
    if (maxXRef > maxX) {
      moveNodes.forEach(i => {
        i?.setPosition({ x: i.getPosition().x - (maxXRef - maxX), y: i.getPosition().y });
      });
      maxXRef = maxX;
    }
  }
  return maxXRef;
};

// 更新母线节点数据BusBar
export function updateBusBarNodeData(
  node: Node<Node.Properties>,
  busbarNeedExtendsMap: {
    addLineIndex: number;
    addLineLength: number;
    // addNode:Node<Node.Properties>
  }[]
) {
  const data = node.getData();
  node.removeData();
  node.updateData({
    ...data,
    busbarNeedExtendsMap,
  });
}

// 节点向下偏移
export function downLeftNodes(
  graph: Graph,
  addedNode: Node<Node.Properties>,
  findItem: TreeNode | null,
  id: number,
  addNodeType: NodeType
) {
  // 排除刚刚创建的id（通过id和type确定，因为id可能重复）
  const ids = getChildrenIds(findItem?.children ?? []).filter(item => item.id + item.element !== id + addNodeType);
  const addedNodeSize = addedNode.getSize();
  changeYPosition(graph, ids, addedNodeSize.height);
}

// 处理母线向右新增节点的变化
export function handleAddNodeByRight(
  node: Node<Node.Properties>,
  originCircleArr: BusBarCircleNumber[],
  originBusbarLength: number,
  findIndex: number,
  addCircleIndex?: number
) {
  // 当前母线的变化
  let arr = originCircleArr.map((item, index) => {
    if (index > findIndex) {
      return {
        circleNumber: item.circleNumber + 1,
        left: EACH_BUSBAR_LENGTH + item.left,
      };
    } else {
      return {
        circleNumber: item.circleNumber,
        left: item.left,
      };
    }
  });
  arr.splice(findIndex, 0, {
    circleNumber: addCircleIndex! + 1,
    left: EACH_BUSBAR_LENGTH * (findIndex + 1),
  });
  arr.sort((a, b) => {
    return a.circleNumber - b.circleNumber;
  });
  originBusbarLength = originBusbarLength + EACH_BUSBAR_LENGTH;
  const tempData = node.getData();
  node.removeData();
  node.updateData({
    ...tempData,
    busbarNeedExtendsMap: {
      ...tempData?.busbarNeedExtendsMap,
      circleArr: arr,
      busbarLength: originBusbarLength,
    },
  });
}

//  处理母线向左新增节点的变化
export function handleAddNodeByLeft(
  node: Node<Node.Properties>,
  originCircleArr: BusBarCircleNumber[],
  originBusbarLength: number,
  allNodeData: TreeNode[],
  currentNodeData: NodeData,
  graph: Graph,
  findIndex: number,
  addCircleIndex?: number
) {
  let arr = originCircleArr.map((item, index) => {
    if (index > findIndex) {
      return {
        ...item,
        circleNumber: item.circleNumber,
        left: item.left + EACH_BUSBAR_LENGTH,
      };
    } else {
      return {
        ...item,
        circleNumber: item.circleNumber - 1,
        left: item.left,
      };
    }
  });
  arr.splice(findIndex, 0, {
    circleNumber: addCircleIndex!,
    left: EACH_BUSBAR_LENGTH + arr[findIndex].left,
    bLinePosition: 0,
  });

  arr.sort((a, b) => {
    return a.circleNumber - b.circleNumber;
  });

  originBusbarLength = originBusbarLength + EACH_BUSBAR_LENGTH;
  const tempData = node.getData();
  node.removeData();
  node.updateData({
    ...tempData,
    busbarNeedExtendsMap: {
      ...tempData?.busbarNeedExtendsMap,
      circleArr: arr,
      busbarLength: originBusbarLength,
    },
  });

  // 只要是向左新增，那么母线上面的元素都得往右挪动一格，后面的元素也得向右移动
  // let { x, y } = node.getPosition();
  // node.setPosition({ x: x - EACH_BUSBAR_LENGTH, y });
  // const pids = findAllPId(allNodeData, currentNodeData.id, currentNodeData.element);
  // console.log('pids', pids);
  // changeXPosition(graph, pids, 280);
}

// 本节点与子节点位置移动
export function moveAddedNodeAndChildrenNodes(
  addedNode: Node<Node.Properties>,
  graph: Graph,
  addNodeType: NodeType,
  addNodeX: number,
  addNodeY: number,
  width: number,
  findIndex: number,
  findItem: TreeNode | null,
  addCircleIndex?: number
) {
  const addedNodeSize = addedNode.getSize();
  let finalX =
    isMeters(addNodeType) ||
    addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
    addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
    addNodeType === NodeType.OTHER_TRANSVERSE
      ? addNodeX + width * findIndex + EACH_BUSBAR_LENGTH / 2 - ADDCIRCLE_RADIUS
      : addNodeX + width * findIndex + EACH_BUSBAR_LENGTH / 2 - addedNodeSize.width! / 2;
  addedNode.setPosition({ x: finalX, y: addNodeY + 30 }); // 母线高度一半加母线下面的线bline

  let ids: {
    id: number;
    element: NodeType;
  }[] = [];
  if (addCircleIndex! > 0) {
    ids = getChildrenIds((findItem?.children ?? []).filter((_, index) => index > findIndex - 1));
  } else {
    ids = getChildrenIds((findItem?.children ?? []).filter((_, index) => index > findIndex));
    // changeXPosition(graph, ids, -280);
  }
  changeXPosition(graph, ids, 280);
}

// 删除树数据
export function removeTreeData(
  allNodeData: TreeNode[],
  type: NodeType,
  id?: Key,
  gateWayList?: GateWayList[],
  fcsList?: FcsList[],
  drawIndex?: number
) {
  if (type === NodeType.GRID_SIDE) {
    allNodeData.splice(drawIndex!, 1);
  }
  if (isMeters(type) || type === NodeType.ANTI_BACKFLOW_DEVICE || type === NodeType.UNIVERSAL_JOINT_OPENING || type === NodeType.OTHER_TRANSVERSE) {
    const findItem = loopTreeTofindId(allNodeData, id!, type);
    const findParentItem = loopTreeTofindParent(allNodeData, id!, type);
    const tempChildren = cloneDeep(findItem?.children ?? []);

    // 修改children的parentId为新节点的parentId
    if (findParentItem?.element === NodeType.BUS_BAR) {
      const findOriginIndex = findParentItem?.children.findIndex(i => i.id === id && i.element === type);
      updateChildrenPId(tempChildren, findParentItem.id as number);
      // 如果下面没有东西了，则直接删除这条
      if (findItem?.children.length === 0) {
        findParentItem.children.splice(findOriginIndex, 1);
      } else {
        findParentItem.children[findOriginIndex] = tempChildren[0];
      }
    } else {
      if (findParentItem) {
        updateChildrenPId(tempChildren, findParentItem.id as number);
        findParentItem.children = tempChildren;
      }
    }
  } else if (type === NodeType.GATEWAY) {
    const findIndexGateway = gateWayList?.findIndex(i => i.id === id);
    gateWayList?.splice(findIndexGateway!, 1);
  } else if (type === NodeType.FCS_CONTROLLER) {
    const findIndexFcsController = fcsList?.findIndex(i => i.id === id);
    fcsList?.splice(findIndexFcsController!, 1);
  } else {
    const findParentItem = loopTreeTofindParent(allNodeData, id!, type);
    if (findParentItem?.element === NodeType.BUS_BAR) {
      const findOriginBusIndex = findParentItem?.children.findIndex(i => i.id === id && i.element === type);
      findParentItem.children.splice(findOriginBusIndex, 1);
    } else {
      if (findParentItem) {
        findParentItem.children = [];
      }
    }
  }
  return allNodeData;
}

// 删除电网侧以及它剩下的所有
export function removeGridSideAndChildren(graph: Graph, allNodeData: TreeNode[], drawIndex?: number) {
  const findItem = allNodeData[drawIndex!];
  const addedAllNodes = graph.getNodes();
  const findCurrentNode = addedAllNodes.find(
    item => item.getData().id === findItem.id && item.getData().element === NodeType.GRID_SIDE
  );
  // 自己也要删除
  findCurrentNode && graph.removeNode(findCurrentNode);
  // 除了自己以外的children也要删除
  const ids = getChildrenIds(findItem?.children ?? []);
  ids.forEach(item => {
    const itemNode = addedAllNodes.find(i => i.getData().id === item.id && i.getData().element === item.element);
    graph.removeNode(itemNode!);
  });
  // 删除电网接入点，电网侧加号回来
  // 寻找父节点（因为是电网侧，所以父节点就是本身）
  const findGridSideEditNode = addedAllNodes.find(
    item => item.getData().id === findItem.id && item.getData().element === NodeType.GRID_SIDE
  );
  findGridSideEditNode &&
    findGridSideEditNode.updateData({
      isShowBottomAddBtn: true,
      value: {
        voltageLevel: undefined,
      },
    });
}

// 暴露需要删除的数据
export function getNeedRemoveData(graph: Graph, allNodeData: TreeNode[], type: NodeType, id?: number) {
  const findItem = loopTreeTofindId(allNodeData, id!, type);
  const findParentItem = loopTreeTofindParent(allNodeData, id!, type);
  const tempChildren = cloneDeep(findItem?.children ?? []);
  const addedAllNodes = graph.getNodes();
  const findCurrentNode = addedAllNodes.find(item => item.getData().id === id && item.getData().element === type);
  return { findItem, findParentItem, tempChildren, addedAllNodes, findCurrentNode };
}

// 删除计量电表或者防逆流时移动位置（辅助装置，向上移动）
export function removeAuxiliaryDevicePosition(
  graph: Graph,
  tempChildren: TreeNode[],
  addedAllNodes: Node<Node.Properties>[],
  findParentItem: TreeNode | null,
  findItem: TreeNode | null,
  findCurrentNode?: Node<Node.Properties>
) {
  // 修改要删除节点下的children的parentId是当前删除节点的父节点
  tempChildren.forEach(item => {
    const children = addedAllNodes.find(i => i.getData().id === item.id && i.getData().element === item.element);
    children?.updateData({ parentId: findParentItem?.id });
  });
  // 改变children的位置
  const ids = getChildrenIds(findItem?.children ?? []);
  changeYPosition(graph, ids, -findCurrentNode?.getSize().height!);
}

// 删除计量电表或者防逆流时（当父节点是母线，且下挂只有一个设备时）
export function removeAuxDeviceWithPisBusbarOnlyOne(
  graph: Graph,
  addedAllNodes: Node<Node.Properties>[],
  allNodeData: TreeNode[],
  findParentItem: TreeNode,
  type: NodeType,
  findCurrentNode?: Node<Node.Properties>,
  id?: number
) {
  const findParentNode = addedAllNodes.find(
    item => item.getData().id === findParentItem.id && item.getData().element === findParentItem.element
  );
  if (findParentNode) {
    // 找到删除的是第几个，然后的全部移动位置，移动的宽度是当前删除节点的宽度
    const findIndex = findParentItem?.children.findIndex(i => i.id === id && i.element === type);

    const busbarNeedExtendsMapTemp: busbarNeedExtendsMapArr = findParentNode?.getData().busbarNeedExtendsMap;
    // console.log('findCurrentNode', findCurrentNode);
    // console.log('findCurrentNode?.getData().busbarNeedExtendsMap', findCurrentNode?.getData().busbarNeedExtendsMap);
    // 获取删除的是左还是右
    const { leftOrRight }: busbarNeedExtendsMapLeftOrRight = findCurrentNode?.getData().busbarNeedExtendsMap;
    if (leftOrRight === 'left') {
      let arr = busbarNeedExtendsMapTemp.circleArr.map((item, index) => {
        if (index > findIndex) {
          return {
            circleNumber: item.circleNumber,
            left: item.left - EACH_BUSBAR_LENGTH,
          };
        } else {
          return {
            circleNumber: item.circleNumber + 1,
            left: item.left,
          };
        }
      });
      arr.splice(findIndex, 1);
      arr.sort((a, b) => {
        return a.circleNumber - b.circleNumber;
      });
      busbarNeedExtendsMapTemp.busbarLength = busbarNeedExtendsMapTemp.busbarLength - EACH_BUSBAR_LENGTH;
      const tempData = findParentNode?.getData();

      findParentNode?.removeData();
      findParentNode?.updateData({
        ...tempData,
        busbarNeedExtendsMap: {
          ...tempData.busbarNeedExtendsMap,
          circleArr: arr,
          busbarLength: busbarNeedExtendsMapTemp.busbarLength,
        },
      });
      // 如果往上查找还有母线，则删除的时候无需
      // 只要是向左删除，那么母线上面的元素都得往左边挪动一格，后面的元素也得向左移动
      const findPParentItem = loopTreeTofindParent(allNodeData, findParentItem.id, findParentItem.element);
      const pids = findAllTopNodeByCurrentNode(allNodeData, findParentItem.id, findParentItem.element);

      // 找到上一根母线
      const findTopBusBarData = findTopBusBar(allNodeData, findPParentItem?.id, findPParentItem?.element);

      // 找到上一根母线到这根母线的所有节点
      const findTopDataBetweenBusbar = findTopDataSplitByBusBar(
        allNodeData,
        findPParentItem?.id,
        findPParentItem?.element
      ).map(i => ({
        element: i?.element,
        id: i?.id,
      }));

      const topBusChildren = findTopBusBarData
        .map(i => i.children)
        .flat()
        .map(c => ({
          element: c?.element,
          id: c?.id,
        }));

      const resArr = reduceArr([...pids, ...findTopDataBetweenBusbar, ...topBusChildren]);

      changeXPosition(graph, resArr, -EACH_BUSBAR_LENGTH);

      const number = arr.filter(i => i.circleNumber < 0).length;
      const edges = graph.getConnectedEdges(findParentNode!, { incoming: true }); // 返回输入边

      edges[0].prop('target', {
        cell: findParentNode,
        anchor: {
          name: 'top',
          args: {
            dx: 1 + number * EACH_BUSBAR_LENGTH,
          },
        },
        connectionPoint: 'anchor',
      }); // 修改终点
    } else {
      // 右边删除（直接删除最后一个）
      const arr = cloneDeep(busbarNeedExtendsMapTemp.circleArr);
      arr.splice(arr.length - 1, 1);
      busbarNeedExtendsMapTemp.busbarLength = busbarNeedExtendsMapTemp.busbarLength - EACH_BUSBAR_LENGTH;
      const tempData = findParentNode?.getData();

      findParentNode?.removeData();
      findParentNode?.updateData({
        ...tempData,
        busbarNeedExtendsMap: {
          ...tempData.busbarNeedExtendsMap,
          circleArr: arr,
          busbarLength: busbarNeedExtendsMapTemp.busbarLength,
        },
      });
    }

    // 右边的元素也都要往左边移动
    const ids = getChildrenIds((findParentItem?.children ?? []).filter((_, index) => index > findIndex!));
    changeXPosition(graph, ids, -EACH_BUSBAR_LENGTH);
  }
}

// 删除母线下面第一个元素（辅助设备，其他的往下都会删除）后，再下一层节点下方恢复上加号
export function reduceNodeTopBtn(
  graph: Graph,
  allNodeData: TreeNode[],
  findItem: TreeNode,
  findParentItem: TreeNode | null
) {
  // 子节点，父节点
  const findChildrenItem = loopTreeTofindId(allNodeData, findItem?.children[0].id!, findItem?.children[0].element!);
  if (findChildrenItem && findParentItem) {
    const addedAllNodes = graph.getNodes();
    const findChildrenNode = addedAllNodes.find(
      item => item.getData().id === findChildrenItem.id && item.getData().element === findChildrenItem.element
    );
    if (hasBottomAddNode(findParentItem.element) && hasTopAddNode(findChildrenItem.element)) {
      findChildrenNode?.updateData({
        isShowTopAddBtn: false,
      });
    } else {
      findChildrenNode?.updateData({
        isShowTopAddBtn: true,
      });
    }
  }
}

export function createFcsGatewayNodes(gatewayList: any[], graph: Graph, x: number, y: number, findIndex?: number) {
  gatewayList.forEach((item, index) => {
    graph.addNode({
      shape: NodeType.GATEWAY, // 指定使用何种图形，默认值为 'rect'
      x: !isNil(findIndex) ? x + 20 + findIndex * 280 : x + 120,
      y: y + (index + 1) * 60,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: item.gatewayId,
        element: NodeType.GATEWAY,
        value: {
          id: item?.gatewayId,
          edgeGatewayId: item?.gatewayId,
          netWorkId: item?.networkId,
          name: item?.gatewayName,
          serialNumber: item?.gatewayNo,
        },
      },
    });
  });
}

// 获取相同等级的母线需要增加的高度
export function getSameGradeLevelNeedAddHeight(
  graph: Graph,
  addedNode: Node<Node.Properties>,
  allNodeData: TreeNode[],
  currentNodeData: NodeData,
  nodeDataValues: any
) {
  let grade: number = 1;
  const topArr = findTopDataSplitWithBusBar(allNodeData, currentNodeData.id, currentNodeData.element);
  const findPBusbar = topArr.find(i => i.element === NodeType.BUS_BAR);

  addedNode.updateData({
    busbarNeedExtendsMap: {
      circleArr: [
        { circleNumber: 0, left: 0 },
        { circleNumber: 1, left: EACH_BUSBAR_LENGTH },
      ],
      busbarLength: EACH_BUSBAR_LENGTH,
      grade,
    },
  });
  // if (findPBusbar) {
  //   const addedAllNodes = graph.getNodes();
  //   const findNode = addedAllNodes.find(
  //     item => item.getData().id === findPBusbar.id && item.getData().element === findPBusbar.element
  //   );
  //   // 找到上层母线的grade
  //   const findPBusbarGrade = findNode?.getData()?.busbarNeedExtendsMap?.grade;
  //   grade = findPBusbarGrade + 1;
  //   // 找到相同等级的母线
  //   const findSameGradeLevelNode = addedAllNodes.find(
  //     item =>
  //       item.getData()?.busbarNeedExtendsMap?.grade === grade && item.getData()?.value?.level === nodeDataValues?.level
  //   );

  //   // 第一次渲染
  //   addedNode.updateData({
  //     busbarNeedExtendsMap: {
  //       circleArr: [
  //         { circleNumber: 0, left: 0 },
  //         { circleNumber: 1, left: EACH_BUSBAR_LENGTH },
  //       ],
  //       busbarLength: EACH_BUSBAR_LENGTH,
  //       grade,
  //     },
  //   });

  //   if (findSameGradeLevelNode) {
  //     // 当前节点
  //     // 找到父节点
  //     // const currentPNode = loopTreeTofindParent(allNodeData, currentNodeData.id, currentNodeData.element);
  //     // 找到高度
  //     let currentHeight: number = 0;
  //     const currentNodeTopArr = findTopDataSplitByBusBar(allNodeData, currentNodeData.id, currentNodeData.element);

  //     currentNodeTopArr.forEach(i => {
  //       const findNode = addedAllNodes.find(item => item.getData().id === i.id && item.getData().element === i.element);
  //       currentHeight += findNode?.getSize().height ?? 0;
  //     });

  //     const findSameGradeLevelNodeId = findSameGradeLevelNode.getData()?.id;
  //     // 找到父节点
  //     const PNode = loopTreeTofindParent(allNodeData, findSameGradeLevelNodeId, NodeType.BUS_BAR);
  //     // 找到高度
  //     let anotherHeight: number = 0;
  //     const topArr = findTopDataSplitByBusBar(allNodeData, PNode?.id, PNode?.element);

  //     topArr.forEach(i => {
  //       const findNode = addedAllNodes.find(item => item.getData().id === i.id && item.getData().element === i.element);
  //       anotherHeight += findNode?.getSize().height ?? 0;
  //     });

  //     if (currentHeight < anotherHeight) {
  //       addedNode.updateData({
  //         busbarNeedExtendsMap: {
  //           circleArr: [
  //             { circleNumber: 0, left: 0 },
  //             { circleNumber: 1, left: EACH_BUSBAR_LENGTH },
  //           ],
  //           busbarLength: EACH_BUSBAR_LENGTH,
  //           height: anotherHeight - currentHeight,
  //           grade,
  //         },
  //       });
  //       let { x, y } = addedNode.getPosition();
  //       addedNode.setPosition({ x: x, y: y + anotherHeight - currentHeight });
  //     } else {
  //       const dataTemp = findSameGradeLevelNode.getData();
  //       findSameGradeLevelNode.removeData();
  //       findSameGradeLevelNode.updateData({
  //         ...dataTemp,
  //         busbarNeedExtendsMap: {
  //           ...dataTemp.busbarNeedExtendsMap,
  //           height: currentHeight - anotherHeight,
  //         },
  //       });
  //       let { x, y } = findSameGradeLevelNode.getPosition();
  //       findSameGradeLevelNode.setPosition({ x: x, y: y + currentHeight - anotherHeight });

  //       const findItem = loopTreeTofindId(allNodeData, dataTemp.id, dataTemp.element);
  //       const ids = getChildrenIds(findItem?.children ?? []);
  //       changeYPosition(graph, ids, currentHeight - anotherHeight);
  //     }
  //   }
  // }
}

// 向右新增上级母线的变化
export function handleTopBusBarByRight(graph: Graph, allNodeData: TreeNode[], currentNodeData: NodeData) {
  const allTopBusBar = findAllTopbusBar(allNodeData, currentNodeData.id, currentNodeData.element);
  if (allTopBusBar && allTopBusBar.length > 0) {
    // 需要移动的母线（上面的母线）后面下的元素都要向后移动
    for (let i = 0; i < allTopBusBar.length; i++) {
      const ids = getChildrenIds(
        (allTopBusBar[i].findParentItem.children ?? []).filter(
          (_: any, index: number) => index > allTopBusBar[i].findParentIndex
        )
      );
      changeXPosition(graph, ids, 280);
    }

    // 上面第一层母线的上面都要向右移(判断index的circleNumber在左还是在右)
    const addedAllNodes = graph.getNodes();
    // const findNode = addedAllNodes.find(
    //   item =>
    //     item.getData().id === allTopBusBar[allTopBusBar.length - 1].findParentItem.id &&
    //     item.getData().element === allTopBusBar[allTopBusBar.length - 1].findParentItem.element
    // );
    // const originBusbarNeedExtendsMap = findNode?.getData()?.busbarNeedExtendsMap;
    // const originCircleArr: BusBarCircleNumber[] = originBusbarNeedExtendsMap?.circleArr;

    // const findCircleIndex = originCircleArr[allTopBusBar[allTopBusBar.length - 1].findParentIndex].circleNumber;
    // if (findCircleIndex <= 0) {
    //   const pids = findAllPId(allNodeData, allTopBusBar[allTopBusBar.length - 1].findParentItem.id, NodeType.BUS_BAR);
    //   changeXPosition(graph, pids, 280);
    // }

    // 找到所有busbar的cls
    // const addedAllNodes = graph.getNodes();
    let allTopBusBarNodes = [];
    for (let i = 0; i < allTopBusBar.length; i++) {
      const findNode = addedAllNodes.find(
        item =>
          item.getData().id === allTopBusBar[i].findParentItem.id &&
          item.getData().element === allTopBusBar[i].findParentItem.element
      );
      const originBusbarNeedExtendsMap = findNode?.getData()?.busbarNeedExtendsMap;
      const originCircleArr: BusBarCircleNumber[] = originBusbarNeedExtendsMap?.circleArr;
      let originBusbarLength: number = originBusbarNeedExtendsMap?.busbarLength;
      const findCircleIndex = originCircleArr[allTopBusBar[i].findParentIndex + 1].circleNumber;

      findNode &&
        allTopBusBarNodes.push({
          findNode,
          // needMoveCircle: findCircleIndex <= 0 ? findCircleIndex : findCircleIndex + 1,
          needMoveCircle: findCircleIndex,
          busbarLength: originBusbarLength + EACH_BUSBAR_LENGTH,
        });
    }

    // 更新busbar的状态，增长母线长度，增加circle偏移量left
    allTopBusBarNodes.forEach((itemBusbarNode, index) => {
      // 因为不触发更新，所以先删除再update（官方bug）
      const tempData = itemBusbarNode.findNode.getData();
      const tempCircleArr: BusBarCircleNumber[] = tempData?.busbarNeedExtendsMap?.circleArr;
      const findCircleIndex = tempCircleArr.findIndex(i => i.circleNumber === itemBusbarNode.needMoveCircle);
      const newArr = tempCircleArr.map((item, index) => {
        if (index >= findCircleIndex) {
          return {
            ...item,
            circleNumber: item.circleNumber,
            left: EACH_BUSBAR_LENGTH + item.left,
          };
        } else {
          return {
            ...item,
            circleNumber: item.circleNumber,
            left: item.left,
          };
        }
      });

      itemBusbarNode.findNode.removeData();
      itemBusbarNode.findNode.updateData({
        ...tempData,
        busbarNeedExtendsMap: {
          ...tempData?.busbarNeedExtendsMap,
          circleArr: newArr,
          busbarLength: itemBusbarNode.busbarLength,
        },
      });
      // 上面已经延长完毕

      if (index === allTopBusBarNodes.length - 1) {
        const findParentItem = loopTreeTofindParent(allNodeData, tempData.id, tempData.element);
        const topArr = findTopDataSplitByBusBar(allNodeData, findParentItem?.id, findParentItem?.element);
        topArr.forEach(iNode => {
          const findCurrentNode = addedAllNodes.find(
            item => item.getData().id === iNode.id && item.getData().element === iNode.element
          );
          if (findCurrentNode) {
            const { x, y } = findCurrentNode.getPosition();
            findCurrentNode.setPosition({ x: x + EACH_BUSBAR_LENGTH, y });
          }
        });
      }
    });
  }
}

// 向左新增上级母线的变化
export function handleTopBusBarByLeft(graph: Graph, allNodeData: TreeNode[], currentNodeData: NodeData) {
  const allTopBusBar = findAllTopbusBar(allNodeData, currentNodeData.id, currentNodeData.element);
  // 需要移动的母线后面下的元素都要向后移动
  if (allTopBusBar && allTopBusBar.length > 0) {
    for (let i = 0; i < allTopBusBar.length; i++) {
      const ids = getChildrenIds(
        (allTopBusBar[i].findParentItem.children ?? []).filter(
          (_: any, index: number) => index > allTopBusBar[i].findParentIndex
        )
      );
      changeXPosition(graph, ids, 280);
    }

    // 延长哪一段
    const addedAllNodes = graph.getNodes();
    let allTopBusBarNodes = [];
    for (let i = 0; i < allTopBusBar.length; i++) {
      const findNode = addedAllNodes.find(
        item =>
          item.getData().id === allTopBusBar[i].findParentItem.id &&
          item.getData().element === allTopBusBar[i].findParentItem.element
      );
      const originBusbarNeedExtendsMap = findNode?.getData()?.busbarNeedExtendsMap;
      const originCircleArr: BusBarCircleNumber[] = originBusbarNeedExtendsMap?.circleArr;
      let originBusbarLength: number = originBusbarNeedExtendsMap?.busbarLength;
      const findCircleIndex = originCircleArr[allTopBusBar[i].findParentIndex + 1].circleNumber;

      findNode &&
        allTopBusBarNodes.push({
          findNode,
          needMoveCircle: findCircleIndex <= 0 ? findCircleIndex : findCircleIndex + 1,
          busbarLength: originBusbarLength + EACH_BUSBAR_LENGTH,
        });
    }

    // 更新busbar的状态，增长母线长度，增加circle偏移量left
    allTopBusBarNodes.forEach((itemBusbarNode, index) => {
      // 因为不触发更新，所以先删除再update（官方bug）
      const tempData = itemBusbarNode.findNode.getData();
      const tempCircleArr: BusBarCircleNumber[] = tempData?.busbarNeedExtendsMap?.circleArr;
      const findCircleIndex = tempCircleArr.findIndex(i => i.circleNumber === itemBusbarNode.needMoveCircle);

      // 母线下面每条节点的位置
      const newArr = tempCircleArr.map((item, index) => {
        if (index >= findCircleIndex) {
          return {
            circleNumber: item.circleNumber,
            left: EACH_BUSBAR_LENGTH + item.left,
          };
        } else {
          return {
            circleNumber: item.circleNumber,
            left: item.left,
          };
        }
      });

      itemBusbarNode.findNode.removeData();
      itemBusbarNode.findNode.updateData({
        ...tempData,
        busbarNeedExtendsMap: {
          ...tempData?.busbarNeedExtendsMap,
          circleArr: newArr,
          busbarLength: itemBusbarNode.busbarLength,
        },
      });
      // 上面是已经延长完毕

      if (index === 0) {
        // 第一层母线下的元素向右偏移
        const tempData = itemBusbarNode.findNode.getData();
        const tempCircleArr: BusBarCircleNumber[] = tempData?.busbarNeedExtendsMap?.circleArr;

        const findCurrentNode = addedAllNodes.find(
          item => item.getData().id === currentNodeData.id && item.getData().element === currentNodeData.element
        );

        const currentNodeTempData = findCurrentNode?.getData().busbarNeedExtendsMap;
        const tempCurrentCircleArr: BusBarCircleNumber[] = currentNodeTempData?.circleArr;

        const length =
          tempCurrentCircleArr[0].left -
          tempCurrentCircleArr[tempCurrentCircleArr.findIndex(i => i.circleNumber === 0)].left;
        const newArr = tempCircleArr.map((item, index) => {
          return {
            ...item,
            bLinePosition: item.circleNumber === itemBusbarNode.needMoveCircle - 1 ? Math.abs(length) : 0,
          };
        });
        itemBusbarNode.findNode.removeData();
        itemBusbarNode.findNode.updateData({
          ...tempData,
          busbarNeedExtendsMap: {
            ...tempData?.busbarNeedExtendsMap,
            circleArr: newArr,
            busbarLength: itemBusbarNode.busbarLength,
          },
        });

        // 向上查找那一条线上的元素都得偏移
        const findParentItem = loopTreeTofindParent(allNodeData, currentNodeData.id, currentNodeData.element);
        const topArr = findTopDataSplitByBusBar(allNodeData, findParentItem?.id, findParentItem?.element);
        topArr.forEach(iNode => {
          const findCurrentNode = addedAllNodes.find(
            item => item.getData().id === iNode.id && item.getData().element === iNode.element
          );
          if (findCurrentNode) {
            const { x, y } = findCurrentNode.getPosition();
            findCurrentNode.setPosition({ x: x + EACH_BUSBAR_LENGTH, y });
          }
        });
      }
      // const { x, y } = itemBusbarNode.findNode.getPosition();
      // itemBusbarNode.findNode.setPosition({ x: x - EACH_BUSBAR_LENGTH, y });

      if (index === allTopBusBarNodes.length - 1) {
        const findParentItem = loopTreeTofindParent(allNodeData, tempData.id, tempData.element);
        const topArr = findTopDataSplitByBusBar(allNodeData, findParentItem?.id, findParentItem?.element);
        topArr.forEach(iNode => {
          const findCurrentNode = addedAllNodes.find(
            item => item.getData().id === iNode.id && item.getData().element === iNode.element
          );
          if (findCurrentNode) {
            const { x, y } = findCurrentNode.getPosition();
            findCurrentNode.setPosition({ x: x + EACH_BUSBAR_LENGTH, y });
          }
        });
      }
    });
  }
}
