import dayjs from 'dayjs';
import { NodeType, SwitchSelectDeviceList, TreeNode } from '../type';
import { V2DispatchUetStructGetTreePostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { addedNodeValue, loopResTreeTofindItem, portGroups } from './graph';
import { Edge, Graph, Node } from '@antv/x6';

// 保存查询的网关fcs信息
export const formatResSingleData = (nodeType: NodeType, data?: any[]) => {
  return (data ?? []).map(i => {
    return {
      id: i.id!,
      element: nodeType,
    };
  });
};

// 将开关节点所选设备集合
export const formatResSwitchList = (treeData: any[]): SwitchSelectDeviceList[] => {
  let res: SwitchSelectDeviceList[] = [];
  function loop(array: any[]) {
    array.forEach(item => {
      if (item.element === NodeType.UNIVERSAL_JOINT_OPENING) {
        res.push({
          id: item.id,
          element: item.element,
          deviceId: item?.dataPropertyConfigs?.[0]?.deviceId,
        });
      }
      if (item.children) loop(item.children);
    });
  }
  loop(treeData);
  return res;
};

// 维护树结构数据（新增电网侧父节点）
export const formatResTreeData = (treeData: any[]) => {
  return treeData.map((item, index, arr) => {
    return {
      id: item.id + NodeType.GRID_SIDE,
      parentId: undefined,
      element: NodeType.GRID_SIDE,
      children: [arr[index]],
    };
  });
};

// 维护电网侧Node
export const formatResTreeDataSideNode = (
  treeData: any[],
  diagrams: V2DispatchUetStructGetTreePostResponse['diagrams']
) => {
  return (treeData ?? []).map((item, index, arr) => {
    return {
      shape: NodeType.GRID_SIDE,
      x: diagrams?.find(i => i.elementId === item.id && i.shape === item.element)?.x!,
      y: diagrams?.find(i => i.elementId === item.id && i.shape === item.element)?.y! - 60,
      data: {
        disableMove: true,
        parentId: null,
        id: item.id + NodeType.GRID_SIDE,
        value: {
          name: '电网侧',
          voltageLevel: (item.data as any)?.voltageLevel,
        },
        element: NodeType.GRID_SIDE,
        isShowAllAddDelBtn: true,
        isShowBottomAddBtn: false,
        isDelete: index > 0 ? true : false,
      },
    };
  });
};

// 处理接口拿到的数据
export function handleFetchData(res: V2DispatchUetStructGetTreePostResponse): any[] {
  const allgraphNodes: any[] = [];
  res.diagrams?.forEach(i => {
    let findItem: any;
    let value: any;
    // 如果是网关单独处理值
    if (i.shape === NodeType.GATEWAY) {
      findItem = null;
      value = addedNodeValue(
        i.shape,
        res.gatewayList?.find(c => c.id === i.elementId)
      );
    } else if (i.shape === NodeType.FCS_CONTROLLER) {
      findItem = null;
      value = addedNodeValue(
        i.shape,
        res.fcsList?.find(c => c.id === i.elementId)
      );
    } else {
      findItem = loopResTreeTofindItem(res?.trees ?? [], i.elementId!, i.shape as NodeType);
      value = {
        ...addedNodeValue(findItem?.element as NodeType, findItem?.data),
        hasChildren: findItem?.children?.length ? true : false,
      };
    }

    allgraphNodes.push({
      shape: i.shape,
      x: i.x,
      y: i.y,
      ports: portGroups,
      data: {
        busbarNeedExtendsMap:
          typeof i.busbarNeedExtendsMap == 'string' ? JSON.parse(i.busbarNeedExtendsMap) : i.busbarNeedExtendsMap,
        disableMove:
          i.shape === NodeType.GATEWAY || i.shape === NodeType.FCS_CONTROLLER || i.shape === NodeType.BUS_BAR
            ? true
            : false, // 是否可移动
        id: i.elementId,
        element: i.shape,
        // 如果上节点是母线则不需要去除上加号，有上加号的不需要展示，因为下加号都展示了
        isShowTopAddBtn: findItem?.parentElement === NodeType.BUS_BAR ? true : false,
        isShowBottomAddBtn: true,
        isShowAllAddDelBtn: true,
        value,
      },
    });
  });
  return allgraphNodes;
}

// 初次渲染电网侧
export function firstRenderSide(allNodeData: TreeNode[]) {
  const dataId = dayjs().valueOf();
  // 维护数组
  allNodeData.push({
    id: dataId,
    parentId: undefined,
    element: NodeType.GRID_SIDE,
    children: [],
  });
  // 初次渲染电网侧
  const addNodeData = {
    shape: NodeType.GRID_SIDE,
    x: 100,
    y: 50,
    data: {
      parentId: null,
      id: dataId,
      value: {
        name: '电网侧',
      },
      element: NodeType.GRID_SIDE,
      isShowAllAddDelBtn: true,
      isShowBottomAddBtn: true,
      isDelete: false,
    },
  };
  return addNodeData;
}

// 预览 后面的电网侧删除按钮去除，隐藏删除和添加按钮，以及isShowNetWork=false（透明度）
export const changePreviewNodeData = (
  addedAllNodes: Node<Node.Properties>[],
  gridSideNodesExceptFirst: TreeNode[],
  isDelete: boolean,
  isShowAllAddDelBtn: boolean,
  isShowNetWork: boolean
) => {
  gridSideNodesExceptFirst.forEach(item => {
    // 电网侧id不会重复
    const gridSideNode = addedAllNodes.find(i => i.getData().id === item.id);
    gridSideNode?.updateData({
      isDelete,
    });
  });
  addedAllNodes.forEach(item =>
    item.updateData({
      isShowAllAddDelBtn,
      isShowNetWork,
    })
  );
};

// 删除所有连接桩和边
export const removeAllEdgeAndPort = (
  addedAllNodes: Node<Node.Properties>[],
  edges: Edge<Edge.Properties>[],
  graph: Graph
) => {
  edges.forEach(i => {
    graph.removeEdge(i);
  });
  addedAllNodes.forEach(i => {
    i.removePorts();
  });
};

// 添加电网侧
export const addGridSide = (allNodeData: TreeNode[], graph?: Graph) => {
  const addedAllNodes = graph?.getNodes();
  const nodesPositionX = addedAllNodes
    ?.filter(m => m.getData().element !== NodeType.GATEWAY && m.getData().element !== NodeType.FCS_CONTROLLER)
    ?.map(i => i.getPosition().x);
  const maxX = Math.max(...(nodesPositionX ?? []));
  const dataId = dayjs().valueOf();
  const addNodeData = {
    shape: NodeType.GRID_SIDE,
    x: maxX + 320,
    y: 50,
    data: {
      disableMove: true,
      parentId: null,
      id: dataId,
      value: {
        name: '电网侧',
      },
      element: NodeType.GRID_SIDE,
      isShowAllAddDelBtn: true,
      isShowBottomAddBtn: true,
      isDelete: true,
    },
  };
  // 画布添加
  graph?.addNode(addNodeData);
  // 维护数组
  allNodeData.push({
    id: dataId,
    parentId: undefined,
    element: NodeType.GRID_SIDE,
    children: [],
  });
};
