// 电压等级
// 枚举: 1,2,3,4,5,6,7

// 枚举备注: 1 :ZERO_POINT_FOUR 2 :TEN 3 :TWENTY 4 :THIRTY_FIVE 5 :SIXTY_SIX 6 :ONE_HUNDRED_TEN 7 :TWO_HUNDRED_AND_TWO 8: SIX
export enum VoltageLevel {
  ZERO_POINT_FOUR = 1,
  TEN = 2,
  TWENTY = 3,
  THIRTY_FIVE = 4,
  SIXTY_SIX = 5,
  ONE_HUNDRED_TEN = 6,
  TWO_HUNDRED_AND_TWO = 7,
  SIX = 8,
  ZERO_POINT_SEVENFIVE = 9,
  ONE_POINT_FIVE = 10,
}

export const VoltageLevelLabel = {
  [VoltageLevel.ZERO_POINT_FOUR]: '0.4kV',
  [VoltageLevel.ZERO_POINT_SEVENFIVE]: '0.75kV',
  [VoltageLevel.ONE_POINT_FIVE]: '1.5kV',
  [VoltageLevel.SIX]: '6kV',
  [VoltageLevel.TEN]: '10kV',
  [VoltageLevel.TWENTY]: '20kV',
  [VoltageLevel.THIRTY_FIVE]: '35kV',
  [VoltageLevel.SIXTY_SIX]: '66kV',
  [VoltageLevel.ONE_HUNDRED_TEN]: '110kV',
  [VoltageLevel.TWO_HUNDRED_AND_TWO]: '220kV',
};

export enum BusBarVoltageLevel {
  ZERO_POINT_FOUR = 1,
  TEN = 2,
  TWENTY = 3,
  THIRTY_FIVE = 4,
  SIXTY_SIX = 5,
  ONE_HUNDRED_TEN = 6,
  TWO_HUNDRED_AND_TWO = 7,
  SIX = 8,
  ZERO_POINT_SEVENFIVE = 9,
  ONE_POINT_FIVE = 10,
  NULL = 11,
}

export const BusBarVoltageLevelLabel = {
  [BusBarVoltageLevel.NULL]: '0kV',
  [BusBarVoltageLevel.ZERO_POINT_FOUR]: '0.4kV',
  [BusBarVoltageLevel.ZERO_POINT_SEVENFIVE]: '0.75kV',
  [BusBarVoltageLevel.ONE_POINT_FIVE]: '1.5kV',
  [BusBarVoltageLevel.SIX]: '6kV',
  [BusBarVoltageLevel.TEN]: '10kV',
  [BusBarVoltageLevel.TWENTY]: '20kV',
  [BusBarVoltageLevel.THIRTY_FIVE]: '35kV',
  [BusBarVoltageLevel.SIXTY_SIX]: '66kV',
  [BusBarVoltageLevel.ONE_HUNDRED_TEN]: '110kV',
  [BusBarVoltageLevel.TWO_HUNDRED_AND_TWO]: '220kV',
};

export enum NodeType {
  // 源节点 负荷节点 储节点 转换节点 分配节点 开关节点 网路节点 其他节点
  SOURCE = 1,
  LOAD = 2,
  STORAGE = 3,
  TRANSFORMER = 4,
  DISTRIBUTION = 5,
  SWITCH = 6,
  NETWORK = 7,
  OTHER = 8,
}

export const NodeTypeLabel = {
  [NodeType.SOURCE]: '源节点',
  [NodeType.LOAD]: '负荷节点',
  [NodeType.STORAGE]: '储节点',
  [NodeType.TRANSFORMER]: '转换节点',
  [NodeType.DISTRIBUTION]: '分配节点',
  [NodeType.SWITCH]: '开关节点',
  [NodeType.NETWORK]: '网络节点',
  [NodeType.OTHER]: '自定义节点',
};

export enum GridConnectionLevel {
  LO = 0,
  L1 = 1,
  L2 = 2,
}

export const GridConnectionLevelLabel = {
  [GridConnectionLevel.LO]: '0.4kV',
  [GridConnectionLevel.L1]: '10kV',
  [GridConnectionLevel.L2]: '20kV',
};

// 电表类型
export enum ElectricityType {
  REAL = 1,
  VIRTUAL = 2,
}

export const ElectricityTypeLabel = {
  [ElectricityType.REAL]: '真实电表',
  [ElectricityType.VIRTUAL]: '虚拟电表',
};

// fcs类型
export enum FcsType {
  BESS1POINT0 = 1,
  BESS2POINT0 = 2,
}

export const FcsTypeLabel = {
  [FcsType.BESS1POINT0]: 'BESS-1.0',
  [FcsType.BESS2POINT0]: 'BESS-2.0',
};

// 防逆流关联设备类型
export enum DeviceType {
  ELEC = 1,
  ANTI = 2,
}

export const DeviceTypeLabels = {
  [DeviceType.ELEC]: '电表',
  [DeviceType.ANTI]: '防逆流装置',
};
