import { Button, Form, Table, Tooltip } from '@maxtropy/components';
import styles from './index.module.scss';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import ShowInput from '@/components/ShowInput';
import { Col, Row, Space } from 'antd';
import { DownOutlined, FileSearchOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { V2BsaStrategyTemplateGetPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import {
  ActionType,
  ActionTypeTypeDisplay,
  ChargingAndDischargingPower,
  ChargingAndDischargingPowerplay,
  PVPeriods,
  PVPeriodsDisplay,
  PeaksAndValleysType,
  PeaksAndValleysTypePowerplay,
  StrategyTemplateType,
  StrategyTemplateTypeDisplay,
  PricedisplayType,
  PricedisplayTypeDisplay,
} from '../type';
import { CreatePriceFormProps, getPriceTimeIndex } from '../utils';
import { isNil } from 'lodash-es';
import { columnsWithCustomCustom, columnsWithCustomFollowMax, columnsWithFollowPrice, PowerListItem } from '../common';
import PeakItem from '../StrategyTemplateEdit/components/PeakItem';
import { ColumnsType } from 'antd/es/table';

interface Props {
  id?: number;
  bsaId?: string | number;
  data?: V2BsaStrategyTemplateGetPostResponse;
  size?: 'small' | 'middle';
  showTemplateName?: boolean;
  tableCanFold?: boolean;
}

const StrategyTemplateDetail: FC<Props> = props => {
  const { data, size = 'middle', showTemplateName = true, tableCanFold = false } = props;
  const [form] = Form.useForm();
  const [tableFolding, setTableFolding] = useState(false);

  const colSpan = size === 'small' ? 12 : 8;

  useEffect(() => {
    if (data && form) {
      const priceData = getPriceTimeIndex(data);
      let chargingAndDischargingPeriodDetailTemp;
      if (data.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate) {
        const allList = [
          data.additionalFields?.plainAndValleyTypeList,
          data.additionalFields?.peakAndSharpTypeList,
        ].flat();

        chargingAndDischargingPeriodDetailTemp = allList.map(item => {
          return data.additionalFields?.chargingAndDischargingPeriodDetail?.find(c => c.peakValleyType === item);
        });
      }

      form.setFieldsValue({
        templateName: data.templateName,
        templateType: StrategyTemplateTypeDisplay[data.templateType as StrategyTemplateType],
        periodTypes: PVPeriodsDisplay[data.additionalFields?.periodTypes as PVPeriods],
        epTemplateId: data.additionalFields?.epTemplateId,
        epTemplateName: data.additionalFields?.epTemplateName,
        timePeriodGranularity:
          PricedisplayTypeDisplay[data.additionalFields?.timePeriodGranularity as PricedisplayType],
        chargingAndDischargingPower:
          ChargingAndDischargingPowerplay[
            data.additionalFields?.chargingAndDischargingPower as ChargingAndDischargingPower
          ],
        chargingAndDischargingPeriodDetail:
          data.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate
            ? chargingAndDischargingPeriodDetailTemp?.map(item => {
                return {
                  ...item,
                  key: Math.random(),
                  startTime: item?.startTime?.slice(0, 5),
                  endTime: item?.endTime?.slice(0, 5),
                  time: PeaksAndValleysTypePowerplay[item?.peakValleyType as PeaksAndValleysType],
                };
              })
            : (data.additionalFields?.chargingAndDischargingPeriodDetail ?? []).map(item => {
                return {
                  ...item,
                  key: Math.random(),
                  startTime: item?.startTime?.slice(0, 5),
                  endTime: item?.endTime?.slice(0, 5),
                };
              }),
        plainAndValleyTypeList: data.additionalFields?.plainAndValleyTypeList
          ?.map(i => PeaksAndValleysTypePowerplay[i as PeaksAndValleysType])
          .join(),
        peakAndSharpTypeList: data.additionalFields?.peakAndSharpTypeList
          ?.map(i => PeaksAndValleysTypePowerplay[i as PeaksAndValleysType])
          .join(),
        price: priceData,
      });
    }
  }, [data, form]);

  const columns = useMemo(() => {
    let columns: ColumnsType<PowerListItem> = [];
    if (!isNil(data?.additionalFields?.periodTypes) && !isNil(data?.additionalFields?.chargingAndDischargingPower)) {
      if (data?.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate) {
        if (data?.additionalFields?.chargingAndDischargingPower === ChargingAndDischargingPower.Custom) {
          columns = columnsWithFollowPrice(undefined, true);
        }
      } else {
        if (data?.additionalFields?.chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
          columns = columnsWithCustomFollowMax;
        } else {
          columns = columnsWithCustomCustom(undefined, true);
        }
      }
    }
    if (tableCanFold) {
      columns.push({
        title: (
          <Button
            type="link"
            onClick={() => {
              setTableFolding(tableFolding => !tableFolding);
            }}
          >
            {tableFolding ? (
              <Space>
                <span>展开表格</span>
                <UpOutlined />
              </Space>
            ) : (
              <Space>
                <span>收起表格</span>
                <DownOutlined />
              </Space>
            )}
          </Button>
        ),
      });
    }
    return columns;
  }, [data, tableCanFold, tableFolding]);

  const priceData = useMemo(() => {
    if (data) {
      const price = new Map<
        Exclude<keyof CreatePriceFormProps, 'timePeriodGranularity' | 'price'>,
        number | undefined
      >();
      data.additionalFields?.chargingAndDischargingPeriodDetail?.forEach(i => {
        price.set(
          ActionTypeTypeDisplay[i.type as ActionType] as Exclude<
            keyof CreatePriceFormProps,
            'timePeriodGranularity' | 'price'
          >,
          i.power
        );
      });
      return getPriceTimeIndex(data);
    }
  }, [data]);

  return (
    <div className={styles.formWrap}>
      <Form form={form}>
        <Row gutter={8}>
          {showTemplateName && (
            <Col span={colSpan}>
              <Form.Item name="templateName" label="策略模板名称">
                <ShowInput />
              </Form.Item>
            </Col>
          )}
          <Col span={colSpan}>
            <Form.Item name="templateType" label="策略模板类型">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item name="periodTypes" label="峰谷时段">
              <ShowInput />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            {data?.additionalFields?.periodTypes === PVPeriods.CustomSettings ? (
              <Form.Item name="timePeriodGranularity" label="时段颗粒度">
                <ShowInput />
              </Form.Item>
            ) : (
              <Form.Item name="epTemplateName" label="电价模板">
                <ShowInputWithOther
                  slot={
                    <Button
                      icon={<FileSearchOutlined />}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      type="link"
                      onClick={() =>
                        window.open(
                          `${window.IOTPLATFORMORIGIN}/operation/energy/contract/electric-rate/detail/${data?.additionalFields?.epTemplateId}`,
                          '_blank'
                        )
                      }
                    >
                      查看
                    </Button>
                  }
                />
              </Form.Item>
            )}
          </Col>
          <Col span={colSpan}>
            <Form.Item
              name="chargingAndDischargingPower"
              label={
                <>
                  充放电功率
                  <Tooltip title="跟随电价模板进行充放电，取控制配置中的最大充电功率和最大放电功率。">
                    <InfoCircleOutlined style={{ marginLeft: 4, marginRight: 4, color: '#FAAD14' }} />
                  </Tooltip>
                </>
              }
            >
              <ShowInput />
            </Form.Item>
          </Col>
          {data?.additionalFields?.periodTypes === PVPeriods.CustomSettings ? (
            <Col span={24}>
              <div style={{ marginBottom: 24 }}>
                <PeakItem displayType={data.additionalFields.timePeriodGranularity} disabled value={priceData} />
              </div>
            </Col>
          ) : (
            <>
              <Col span={colSpan}>
                <Form.Item name="plainAndValleyTypeList" label="充电时段">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={colSpan}>
                <Form.Item name="peakAndSharpTypeList" label="放电时段">
                  <ShowInput />
                </Form.Item>
              </Col>
            </>
          )}

          {data?.additionalFields?.chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower &&
          data?.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate ? (
            <></>
          ) : (
            <Col span={24}>
              <Form.Item
                name="chargingAndDischargingPeriodDetail"
                style={{ marginBottom: 0 }}
                valuePropName="dataSource"
              >
                <Table
                  rowKey={_ => Math.random()}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 340 }}
                  className={tableFolding ? styles.tableFolding : undefined}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

const ShowInputWithOther = ({ value, slot }: { value?: ReactNode; slot: ReactNode }) => {
  return (
    <>
      <ShowInput value={value} style={{ display: 'inline-block', marginRight: 10 }} />
      {slot}
    </>
  );
};

export default StrategyTemplateDetail;
