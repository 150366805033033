import { Graph, Node } from '@antv/x6';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import {
  calcMaxLength,
  classificateNodeType,
  firstRenderSide,
  handleCommonExpand,
  handleFetchData,
  initTopoGraph,
  unique,
} from './utils';
import {
  V2GreenMicrowebShowcaseDataPropertyDataPostResponse,
  V2GreenMicrowebShowcaseTreePostResponse,
  apiV2GreenMicrowebShowcaseDataPropertyDataPost,
  apiV2GreenMicrowebShowcaseTreePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { TreeNode } from './type';
import { FcsList, GateWayList, NodeType, SwitchSelectDeviceList } from '@/pages/SchedulingTopology/type';
import {
  formatResSingleData,
  formatResSwitchList,
  formatResTreeData,
  formatResTreeDataSideNode,
} from '@/pages/SchedulingTopology/utils/dataUtil';
import { graphAllNodesAddChild } from '@/pages/SchedulingTopology/utils/draw';
import registerCustomerNode from './registerCustomerNode';
import { ShareDataContext } from '../..';
import CreatingModal from '../Loading/LoadingModal';
import { chunk, isNil } from 'lodash-es';
import { useRequest } from 'ahooks';
import { SwitchPropertyId } from './components/UniversalSwitch';

export type treeItemProps = Exclude<V2GreenMicrowebShowcaseTreePostResponse['trees'], undefined>[number];
export type propertyItemProps = Exclude<V2GreenMicrowebShowcaseDataPropertyDataPostResponse['list'], undefined>[number];
export type dataPropertyConfigsListProps = Exclude<treeItemProps['dataPropertyConfigs'], undefined>[number];
export type dataPropertyConfigsItemProps = Exclude<dataPropertyConfigsListProps['list'], undefined>[number];

const pollingInterval = 1000 * 60; // 60秒轮询一次
export const ITEM_HEIGHT = 56; // 每一个数据点高

const TopoBox = () => {
  const graphRef = useRef<Graph>();
  const refBox = useRef<HTMLDivElement>();
  const [canvasLoading, setCanvasLoading] = useState<boolean>(false); // 加载状态
  const allNodeData = useRef<TreeNode[]>([]);
  const gatewayList = useRef<GateWayList[]>([]);
  const fcsList = useRef<FcsList[]>([]); // Fcs节点
  const switchSelectDeviceListRef = useRef<SwitchSelectDeviceList[]>([]); // Fcs节点
  const [dispatchUetStructId, setDispatchUetStructId] = useState<number>();
  const { isExpand, configId, confList } = useContext(ShareDataContext); // 展开与收起,组态id，组态列表

  useEffect(() => {
    if (!confList || !configId) return;
    setDispatchUetStructId(confList.find(item => item.id === configId)?.dispatchUetStructId);
  }, [confList, configId]);

  // 初始化画布
  useEffect(() => {
    if (!refBox.current) return;
    const graph = initTopoGraph(refBox.current!);
    graphRef.current = graph;
  }, []);

  // 初始化
  useEffect(() => {
    if (!configId) return;
    if (graphRef.current) {
      graphRef.current.clearCells();
    }
    setCanvasLoading(true);
    apiV2GreenMicrowebShowcaseTreePost({ id: configId }).then(res => {
      if (!res) {
        const { allNodeDataTemp, addNodeData } = firstRenderSide(allNodeData.current);
        allNodeData.current = allNodeDataTemp;
        // 画布添加
        graphRef.current!.addNode(addNodeData);
      } else {
        // 渲染数据
        const allgraphNodes = handleFetchData(res);
        graphRef.current!.addNodes(allgraphNodes);
        // 维护网关与fcs（单独的）
        gatewayList.current = formatResSingleData(NodeType.GATEWAY, res?.gatewayList);
        fcsList.current = formatResSingleData(NodeType.FCS_CONTROLLER, res?.fcsList);
        // 维护开关所选设备（当轮巡时不会重新绘制节点）
        switchSelectDeviceListRef.current = formatResSwitchList(res?.trees ?? []);
        // 维护树结构（电网侧不是实体）
        allNodeData.current = formatResTreeData(res?.trees ?? []);
        // 维护电网侧元素Node（电网侧不是实体，后端数据不返回，因此前端新加）
        const sideNodes = formatResTreeDataSideNode(res?.trees ?? [], res.diagrams);
        graphRef.current!.addNodes(sideNodes);
        // 添加母线的连线
        graphAllNodesAddChild(graphRef.current!, allNodeData.current);
        // 基于电网侧节点进行80%缩放
        const nodes = graphRef.current?.getNodes();
        const node = nodes?.find(item => item.getData().element === NodeType.GRID_SIDE);
        if (node) {
          const { x, y } = node!.getPosition();
          graphRef.current?.zoomTo(0.8, { center: { x, y } });
        }
      }
      setCanvasLoading(false);
    });
  }, [configId]);

  registerCustomerNode(graphRef.current, allNodeData.current, switchSelectDeviceListRef.current, configId);

  // 每隔一分钟查询一次数据
  useRequest(
    async () => {
      const nodes = graphRef.current?.getNodes();
      await updateDataProperty(nodes, dispatchUetStructId);
    },
    {
      pollingInterval,
      ready: !!dispatchUetStructId && !canvasLoading,
    }
  );

  useEffect(() => {
    // 默认展开,等图渲染之后才能有node
    if (!isNil(canvasLoading) && !isNil(isExpand)) {
      const nodes = graphRef.current?.getNodes();
      // 通过dataPropertyConfigs改变节点的Y轴位置与高度
      nodes?.forEach(item => {
        const itemData = item.getData();
        let length: number = 0;
        // 如果是开关需要有记忆所选设备的功能，因为数据点数量不同，展开收起的高度也不一样
        const findItem = switchSelectDeviceListRef.current.find(
          item => item.id === itemData?.id && itemData?.element === NodeType.UNIVERSAL_JOINT_OPENING
        );
        if (findItem) {
          const allDeviceLengthArr = (
            (itemData?.value?.dataPropertyConfigs as treeItemProps['dataPropertyConfigs']) ?? []
          ).find(i => i.deviceId === findItem.deviceId);
          // 默认选择第一个设备(不包含合闸/分闸数据属性)
          length = (allDeviceLengthArr?.list?.filter(item => item?.dataPropertyId !== SwitchPropertyId) ?? []).length;
        } else {
          length = ((itemData?.value?.dataPropertyConfigs as treeItemProps['dataPropertyConfigs']) ?? [])
            .map(item => {
              return item.list;
            })
            .flat().length;
        }
        const height = isExpand
          ? classificateNodeType(itemData?.element) + ITEM_HEIGHT * calcMaxLength(length)
          : classificateNodeType(itemData?.element);
        const extraHeight = itemData?.element === NodeType.UNIVERSAL_JOINT_OPENING ? (length > 0 ? 96 : 0) : 0; // 下拉框高度合分闸状态以及边距等

        if (itemData.isExpand !== isExpand || isNil(itemData.isExpand)) {
          handleCommonExpand(
            itemData?.value?.id,
            itemData?.element,
            item,
            height,
            isExpand,
            length,
            graphRef.current,
            allNodeData.current,
            extraHeight
          );
          // 并通知子节点改变dom
          item.updateData({
            isExpand,
          });
        }
      });
    }
  }, [isExpand, canvasLoading]);

  return (
    <>
      <div className={styles.topo_box}>
        {canvasLoading && <CreatingModal loading={canvasLoading} content={'画布加载中'} />}
        <div
          className={styles.appContent}
          ref={d => {
            if (d) {
              refBox.current = d;
            }
          }}
        />
      </div>
    </>
  );
};
export default TopoBox;

const updateDataProperty = async (nodes?: Node<Node.Properties>[], dispatchUetStructId?: number) => {
  let allDataProperty = nodes
    ?.map(item => {
      const itemData = item.getData();
      const dataPropertyConfigs: treeItemProps['dataPropertyConfigs'] = item.getData()?.value?.dataPropertyConfigs;
      return unique((dataPropertyConfigs ?? []).map(c => c?.deviceId))
        ?.filter(c => !isNil(c))
        .map(deviceId => {
          return {
            deviceId,
            elementId: itemData.id,
            element: itemData.element,
            uetStructId: dispatchUetStructId,
          };
        });
    })
    .flat();
  // 拆分10个为一组
  const chunksArr = chunk(allDataProperty, 10);
  // 查询数据点
  const res = await Promise.all(
    chunksArr.map(async itemList => {
      const res = await apiV2GreenMicrowebShowcaseDataPropertyDataPost({
        list: itemList,
      });
      return res.list;
    })
  );
  let flatArr = res?.flat();
  nodes?.forEach(item => {
    let res: propertyItemProps[] = [];
    const itemData = item.getData();
    flatArr?.forEach(item => {
      if (item?.element === itemData.element && item?.elementId === itemData.id) {
        res.push(item!);
      }
    });
    const originValues = item.getData()?.value;
    item.updateData({
      value: {
        ...originValues,
        deviceInfos: res,
      },
    });
  });
};
