export enum StrategyTemplateType {
  PSVF = 1,
}

export const StrategyTemplateTypeDisplay = {
  [StrategyTemplateType.PSVF]: '削峰填谷',
};

export enum PVPeriods {
  FollowEpriceTemplate = 1,
  CustomSettings = 2,
}

export const PVPeriodsDisplay = {
  [PVPeriods.FollowEpriceTemplate]: '跟随电价模板',
  [PVPeriods.CustomSettings]: '自定义设置',
};

export enum ChargingAndDischargingPower {
  FollowMaxPower = 1,
  Custom = 2,
}

export const ChargingAndDischargingPowerplay = {
  [ChargingAndDischargingPower.FollowMaxPower]: '跟随最大充/放电功率',
  [ChargingAndDischargingPower.Custom]: '自定义',
};

export enum PeaksAndValleysType {
  Sharp = 1,
  Peak = 2,
  Flat = 3,
  Valley = 4,
}

export const PeaksAndValleysTypePowerplay = {
  [PeaksAndValleysType.Sharp]: '尖时段',
  [PeaksAndValleysType.Peak]: '峰时段',
  [PeaksAndValleysType.Flat]: '平时段',
  [PeaksAndValleysType.Valley]: '谷时段',
};

export enum PricedisplayType {
  HALF = 0,
  HOUR = 1,
}

export const PricedisplayTypeDisplay = {
  [PricedisplayType.HALF]: '半点',
  [PricedisplayType.HOUR]: '整点',
};

export enum ActionType {
  Charge = 1,
  DisCharge = 2,
  StandBy = 3,
}

export const ActionTypeTypeDisplay = {
  [ActionType.Charge]: '充电',
  [ActionType.DisCharge]: '放电',
  [ActionType.StandBy]: '待机',
};

export interface ActionTypeTime {
  type: ActionType;
  intervalStart: number;
  intervalEnd: number;
}

// 充放电待机颜色
export const ActionTypeColor = {
  [ActionType.Charge]: '#57FB8B',
  [ActionType.DisCharge]: '#00ADFF',
  [ActionType.StandBy]: '#FFFFFF20',
};
