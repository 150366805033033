import { Graph } from '@antv/x6';
import {
  V2DispatchUetStructMeterBsaOptionalItemPostResponse,
  V2DispatchUetStructNodeElementLinkPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Key } from 'react';
import {
  BusBarNodeAndIndex,
  FcsList,
  GateWayList,
  NodeData,
  NodesTypeSort,
  NodeType,
  NodeTypeClass,
  NodeTypeClassDisplay,
  NodeTypeDisplay,
  TreeNode,
  TreeNodeSubmit,
} from '../type';
import { addedNodeValue, isMeters, loopTreeTofindParent, loopTreeTofindParentAndIndex, treeToArray } from './graph';
import transformer from '../imgs/transformer.gif';
import antiBackflowDevice from '../imgs/antiBackflowDevice.gif';
import transformerMeter from '../imgs/transformerMeter.png';
import backupBattery from '../imgs/backupBattery.gif';
import energyStorage from '../imgs/energyStorage.gif';
import bsaMeter from '../imgs/bsaMeter.png';
import charger from '../imgs/charger.gif';
import loadMeter from '../imgs/loadMeter.png';
import FCS from '../imgs/FCS.png';
import gateway from '../imgs/gateway.gif';
import soureMeter from '../imgs/soureMeter.png';
import poweGrid from '../imgs/poweGrid.gif';
import load from '../imgs/load.gif';
import PV from '../imgs/PV.gif';
import DCAC from '../imgs/DCAC.gif';
import DC from '../imgs/DC.gif';
import other from '../imgs/other.gif';
import combinerBox from '../imgs/combinerBox.gif';

// 当前节点向上遍历，找到所有母线与需要延长的index
export function findAllTopbusBar(allNodeDataRef?: TreeNode[], id?: Key, element?: NodeType) {
  const loopNode = (
    allNodeDataRef?: TreeNode[],
    id?: Key,
    element?: NodeType,
    nodes: BusBarNodeAndIndex[] = []
  ): BusBarNodeAndIndex[] => {
    const findParent = loopTreeTofindParentAndIndex(allNodeDataRef ?? [], id!, element!);
    if (findParent?.findParentItem) {
      if (findParent.findParentItem.element === NodeType.BUS_BAR) {
        nodes.push({
          findParentItem: findParent.findParentItem,
          findParentIndex: findParent.findParentIndex,
        });
      }
      return loopNode(allNodeDataRef, findParent.findParentItem.id, findParent.findParentItem.element, nodes);
    } else {
      return nodes;
    }
  };
  const nodes = loopNode(allNodeDataRef ?? [], id!, element!);
  return nodes;
}

// 当前节点向上遍历，找到母线截取
export function findTopDataSplitByBusBar(allNodeDataRef?: TreeNode[], id?: Key, element?: NodeType) {
  const loopNode = (
    allNodeDataRef?: TreeNode[],
    id?: number | string,
    element?: NodeType,
    nodes: any[] = []
  ): any[] => {
    const findItem = loopTreeTofindId(allNodeDataRef ?? [], id!, element!);
    if (findItem?.element === NodeType.BUS_BAR) {
      return [];
    } else {
      nodes.push(findItem);
      const findParentItem = loopTreeTofindParent(allNodeDataRef ?? [], id!, element!);
      if (findParentItem && findParentItem.element !== NodeType.BUS_BAR) {
        return loopNode(allNodeDataRef, findParentItem.id, findParentItem.element, nodes);
      } else {
        return nodes;
      }
    }
  };
  const nodes = loopNode(allNodeDataRef ?? [], id!, element!);
  return nodes;
  // const loopNode = (data: TreeNode[], nodes: any[] = []): any[] => {
  //   for (const node of data) {
  //     console.log('node', node);
  //     const id = node.id + node.element;
  //     nodes.push(node);
  //     if (id === nodeKey) return nodes;
  //     if (node.children) {
  //       const findChildren = loopNode(node.children, nodes);
  //       if (findChildren?.length) return findChildren;
  //     }
  //   }
  //   return [];
  // };
  // const nodes = loopNode(allNodeDataRef ?? []);
  // return nodes;
}

// 向上查找母线，找到母线以及中间的元素停止
export function findTopDataSplitWithBusBar(allNodeDataRef?: TreeNode[], id?: Key, element?: NodeType) {
  const loopNode = (
    allNodeDataRef?: TreeNode[],
    id?: number | string,
    element?: NodeType,
    nodes: any[] = []
  ): any[] => {
    const findItem = loopTreeTofindId(allNodeDataRef ?? [], id!, element!);
    if (findItem?.element === NodeType.BUS_BAR) {
      nodes.push(findItem);
      return nodes;
    } else {
      nodes.push(findItem);
      const findParentItem = loopTreeTofindParent(allNodeDataRef ?? [], id!, element!);
      if (findParentItem) {
        return loopNode(allNodeDataRef, findParentItem.id, findParentItem.element, nodes);
      } else {
        return nodes;
      }
    }
  };
  const nodes = loopNode(allNodeDataRef ?? [], id!, element!);
  return nodes;
}

// 当前节点向下遍历，找到母线截取
export function findBottomDataSplitByBusBar(findItemChildren?: TreeNode[]) {
  const loopNode = (data: TreeNode[], nodes: any[] = []): any => {
    if (!data.length) return nodes;
    for (const node of data) {
      nodes.push(node);
      if (node.element === NodeType.BUS_BAR) {
        return nodes;
      } else if (node.children && node.children.length) {
        return loopNode(node.children, nodes);
      } else {
        return nodes;
      }
    }
  };
  const nodes = loopNode(findItemChildren ?? []);
  return nodes;
}

// 找到下一层的母线
export function findBottomBusBar(findItemChildren?: TreeNode[]) {
  const loopNode = (data: TreeNode[]): any => {
    if (!data.length) return null;
    for (const node of data) {
      if (node.element === NodeType.BUS_BAR) {
        return node;
      } else if (node.children && node.children.length) {
        return loopNode(node.children);
      } else {
        return null;
      }
    }
  };
  const findNode = loopNode(findItemChildren ?? []);
  return findNode;
}

// 当前节点向下遍历，找到指定母线截取
export function findAllDataSplitByItemBusBar(currentNodeData: NodeData, findItemChildren?: TreeNode[]) {
  const loopNode = (currentNodeData: NodeData, data: TreeNode[], nodes: any[] = []): any => {
    if (!data.length) return nodes;
    for (const node of data) {
      nodes.push(node);
      if (node.element === NodeType.BUS_BAR && node.id === currentNodeData.id) {
        nodes.pop();
        return nodes;
      } else {
        if (node.children && node.children.length) {
          loopNode(currentNodeData, node.children, nodes);
        }
      }
    }
    return nodes;
  };
  const nodes = loopNode(currentNodeData, findItemChildren ?? []);
  return nodes;
}

// 树形结构扁平化
// export function treeToArr(data: TreeNode[], res: any[] = []) {
//   console.log('data', data);
//   if (!data || data.length === 0) return [];
//   data.forEach(item => {
//     res.push({ id: item?.id, element: item?.element });
//     if (item?.children && item?.children.length !== 0) {
//       treeToArr(item?.children, res);
//     }
//   });
//   return res;
// }

// 查找最大的X
export function findItemSideMax(allNodeDataTemp?: TreeNode[], graph?: Graph) {
  const addedAllNodes = graph?.getNodes();
  const filterNodes = (addedAllNodes ?? [])
    .filter(m => m.getData().element !== NodeType.GATEWAY && m.getData().element !== NodeType.FCS_CONTROLLER)
    .filter(i => {
      const key = i.getData().id + i.getData().element;
      return treeToArray(allNodeDataTemp ?? []).find(item => item?.id + item?.element === key);
    });
  const filterNodesPositionX = filterNodes?.map(i => i.getPosition().x);
  const maxX = Math.max(...(filterNodesPositionX ?? []));
  return maxX;
}

// 查找该元素是第几棵树下的
export function findDrawIndexByCurrentNode(currentNodedata: any, allNodeDataRef?: TreeNode[]) {
  let drawIndex: number = 0;
  allNodeDataRef?.forEach((item, index) => {
    if (currentNodedata.element === NodeType.GRID_SIDE) {
      const index = allNodeDataRef.findIndex(i => i.id === currentNodedata.id);
      drawIndex = index;
    } else {
      const arr = findTreeIndexByNode(item.children, currentNodedata.id, currentNodedata.element);
      if (arr.length > 0) {
        drawIndex = index;
      }
    }
  });
  return drawIndex;
}

// 递归遍历查找是下哪颗树下
export function findTreeIndexByNode(children: TreeNode[], id: number, element: NodeType) {
  const loopNode = (data: TreeNode[], nodes: any[] = []): any[] => {
    for (const node of data) {
      const idAndElement = node.id + node.element;
      nodes.push(node);
      if (idAndElement === id + element) return nodes;
      if (node.children) {
        const findChildren = loopNode(node.children, nodes);
        if (findChildren?.length) return findChildren;
      }
    }
    return [];
  };
  const nodes = loopNode(children ?? []);
  return nodes;
}

// formatNodeLinkData
export const formatNodeLinkData = (responselist: V2DispatchUetStructNodeElementLinkPostResponse['list']) => {
  let list: { key: string; label: string; children: any }[] = [];
  (responselist ?? []).forEach(item => {
    list.push({
      key: item.name + 'class',
      label: NodeTypeClassDisplay[item.name as NodeTypeClass],
      children: item.children ? formatNodeLinkDataExceptOne(item.children) : [],
    });
  });
  return list;
};

export const formatNodeLinkDataExceptOne = (
  responselist: V2DispatchUetStructNodeElementLinkPostResponse['list']
): any => {
  if (!responselist) return [];
  return responselist.map(item => {
    return {
      key: item.name,
      label: NodeTypeDisplay[item.name as NodeType],
      children: item.children ? formatNodeLinkDataExceptOne(item.children) : [],
    };
  });
};

// format储能单元
export const formatBsaUnitData = (responselist: V2DispatchUetStructMeterBsaOptionalItemPostResponse['list']) => {
  let list: { key: number; label: string; children: any }[] = [];
  (responselist ?? []).forEach(item => {
    list.push({
      key: item.bsaId!,
      label: item.bsaName ?? '--',
      children: item.bsaList ? formatBsaUnitDataExceptOne(item.bsaList) : [],
    });
  });
  return list;
};

export const formatBsaUnitDataExceptOne = (
  responselist: Exclude<V2DispatchUetStructMeterBsaOptionalItemPostResponse['list'], undefined>[number]['bsaList']
): any => {
  if (!responselist) return [];
  return responselist.map(item => {
    return {
      key: item.bsaItemId,
      label: item.bsaItemName ?? '--',
      disabled: item.disable,
    };
  });
};

// 通过monitorid查找父节点id
export const findMonitorPId = (res: V2DispatchUetStructMeterBsaOptionalItemPostResponse['list'], id: number) => {
  if (!res) return;
  for (let i = 0; i < res.length; i++) {
    if (res[i].bsaList?.map(i => i.bsaItemId).includes(id)) {
      return res[i].bsaId;
    }
  }
};

// 节点类型排序
export function sortNodesType(list: NodesTypeSort[]) {
  const newList: NodesTypeSort[] = list.sort((star, next) => {
    let order = [
      NodeTypeClass.SOURCE + 'class',
      NodeTypeClass.LOAD + 'class',
      NodeTypeClass.STORAGE + 'class',
      NodeTypeClass.TRANSFORM + 'class',
      NodeTypeClass.DISTRIBUTIONC + 'class',
      NodeTypeClass.SWITCH + 'class',
      NodeTypeClass.NETWORK + 'class',
    ];
    return order.indexOf(star.key) - order.indexOf(next.key);
  });
  return newList;
}

export function exceptAnti(element: NodeType): boolean {
  return (
    element === NodeType.PVA ||
    element === NodeType.BSA ||
    element === NodeType.CHARGING_STATION ||
    element === NodeType.LOAD ||
    element === NodeType.BACKUP_POWER ||
    element === NodeType.ANTI_BACKFLOW_DEVICE
  );
}

// 变更树中id与children的Pid
export function updateTreeItemIdAndChildrenPId(values: any, type: NodeType, allNodeData: TreeNode[]) {
  const drawIndex = findDrawIndexByCurrentNode({ id: values.originId, element: type }, allNodeData);
  const findOriginItem = loopTreeTofindId([allNodeData[drawIndex]], values.originId, type);
  if (findOriginItem) {
    findOriginItem.id = values.id;
    if (findOriginItem.children && findOriginItem.children.length > 0) {
      updateChildrenPId(findOriginItem.children, values.id);
    }
  }
}

//  编辑节点（改变元素数据以及树形结构数据）
export function updateNodeDataAndTreeData(
  graphRef: Graph,
  type: NodeType,
  values: any,
  allNodeData: TreeNode[],
  gatewayList: GateWayList[],
  fcsList: FcsList[]
) {
  const addedAllNodes = graphRef.getNodes();
  const findNeedEditNode = addedAllNodes.find(
    item => item.getData().id === values.originId && item.getData().element === type
  );
  const value = addedNodeValue(type!, values);
  if (findNeedEditNode) {
    if (type === NodeType.GRID_ACCESS_POINT) {
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
      // 如果编辑的是电网接入点那么，电网侧的电压也要变化
      const drawIndex = findDrawIndexByCurrentNode({ id: values.originId, element: type }, allNodeData);
      const findParentItem = loopTreeTofindParent([allNodeData[drawIndex]], values.originId, type);
      const findGridSideEditNode = addedAllNodes.find(
        item => item.getData().element === NodeType.GRID_SIDE && item.getData().id === findParentItem?.id
      );
      findGridSideEditNode!.updateData({
        isShowAdd: false,
        value: {
          voltageLevel: values.voltageLevel,
        },
      });
      // 因为修改的时候id变更，所以整棵树需要变化
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
    } else if (type === NodeType.TRANSFORMER) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.BUS_BAR) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (isMeters(type)) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.PVA) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.BSA) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      // bsa更新后，所有的bsa都得更新
      const findNeedEditAllBsaNode = addedAllNodes.filter(
        item => item.getData().value?.bsaId === value.bsaId && item.getData().element === type
      );
      (findNeedEditAllBsaNode ?? []).forEach(item => {
        const itemData = item.getData();
        item.updateData({
          ...itemData,
          value: {
            ...itemData.value,
            controlList: value.controlList,
          },
        });
      });
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.LOAD) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.GATEWAY) {
      const originItem = gatewayList.find(i => i.id === values.originId);
      if (originItem) {
        originItem.id = values.id;
      }
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.FCS_CONTROLLER) {
      const originItem = fcsList.find(i => i.id === values.originId);
      if (originItem) {
        originItem.id = values.id;
      }
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.BACKUP_POWER) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.ANTI_BACKFLOW_DEVICE) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.CHARGING_STATION) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.UNIVERSAL_JOINT_OPENING) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    } else if (type === NodeType.OTHER_VERTICAL || type === NodeType.OTHER_TRANSVERSE) {
      updateTreeItemIdAndChildrenPId(values, type, allNodeData);
      findNeedEditNode.updateData({
        id: value.id,
        value,
      });
    }
  }
}

// 处理提交的tree
export function formatSubmitValue(graphRef?: Graph, allNodeData?: TreeNode[]) {
  const diagrams = graphRef
    ?.getNodes()
    .map(i => {
      return {
        elementId: i.getData().id,
        x: i.getPosition().x,
        y: i.getPosition().y,
        shape: i.getData().element,
        busbarNeedExtendsMap: i.getData()?.busbarNeedExtendsMap
          ? JSON.stringify(i.getData()?.busbarNeedExtendsMap)
          : undefined,
      };
    })
    .filter(item => item.shape !== NodeType.GRID_SIDE);
  const trees = ((allNodeData ?? []).map(i => i.children[0]) as TreeNodeSubmit[]).filter(item => item !== undefined);
  trees.forEach(i => {
    i.parentId = undefined;
  });
  return { diagrams, trees };
}

// 通过id和element查找树中的某项item
export function loopTreeTofindId(nodes: TreeNode[], searchId: number | string, element: NodeType): TreeNode | null {
  let res: TreeNode | null = null;
  function loop(array: TreeNode[]) {
    array.forEach((item: TreeNode) => {
      if (item.id === searchId && item.element === element) {
        res = item;
      }
      if (item.children) loop(item.children);
    });
  }
  loop(nodes);
  return res;
}

// 修改数组中某一属性的属性值(修改节点的父节点id)
export function updateChildrenPId(children: TreeNode[], id: number) {
  for (let i = 0; i < children.length; i++) {
    children[i].parentId = id;
  }
}

// 从当前节点开始查找所有pId
export function findAllPId(allNodeDataRef?: TreeNode[], id?: Key, element?: NodeType) {
  const loopNode = (
    allNodeDataRef?: TreeNode[],
    id?: Key,
    element?: NodeType,
    nodes: { id: Key; element: NodeType }[] = []
  ): { id: Key; element: NodeType }[] => {
    const findParent = loopTreeTofindParent(allNodeDataRef ?? [], id!, element!);
    if (findParent) {
      nodes.push({
        id: findParent.id,
        element: findParent.element,
      });
      return loopNode(allNodeDataRef, findParent.id, findParent.element, nodes);
    } else {
      return nodes;
    }
  };
  const nodes = loopNode(allNodeDataRef ?? [], id!, element!);
  return nodes;
}

// 当前节点向下遍历找出所有的子
// export function findAllSonData(id?: Key, element?: NodeType, findItemChildren?: TreeNode[]) {
//   const loopNode = (id?: Key, element?: NodeType, data: TreeNode[], nodes: any[] = []): any => {
//     if (!data.length) return nodes;
//     for (const node of data) {
//       nodes.push(node);
//       if (node.element === NodeType.BUS_BAR && node.id === currentNodeData.id) {
//         nodes.pop();
//         return nodes;
//       } else {
//         if (node.children && node.children.length) {
//           loopNode(currentNodeData, node.children, nodes);
//         }
//       }
//     }
//     return nodes;
//   };
//   const nodes = loopNode(id, element, findItemChildren ?? []);
//   return nodes;
// }

// 向上查找上一条母线
export function findTopBusBar(allNodeDataRef?: TreeNode[], id?: Key, element?: NodeType) {
  const loopNode = (
    allNodeDataRef?: TreeNode[],
    id?: number | string,
    element?: NodeType,
    nodes: any[] = []
  ): any[] => {
    const findItem = loopTreeTofindId(allNodeDataRef ?? [], id!, element!);
    if (findItem?.element === NodeType.BUS_BAR) {
      nodes.push(findItem);
      return nodes;
    } else {
      const findParentItem = loopTreeTofindParent(allNodeDataRef ?? [], id!, element!);
      if (findParentItem) {
        return loopNode(allNodeDataRef, findParentItem.id, findParentItem.element, nodes);
      } else {
        return nodes;
      }
    }
  };
  const nodes = loopNode(allNodeDataRef ?? [], id!, element!);
  return nodes;
}

// 当前节点向上所有的节点
export function findAllTopNodeByCurrentNode(allNodeData?: TreeNode[], id?: Key, element?: NodeType) {
  const allNodesArr = treeToArray(allNodeData ?? []);
  const findIndex = allNodesArr.findIndex(item => item.id === id && item.element === element);
  const resArr = allNodesArr.slice(0, findIndex);
  return resArr;
}

export interface NodeTypeAndIds {
  element: NodeType;
  id: number;
}

// 对象数组去重
export function reduceArr(arr: NodeTypeAndIds[]) {
  const uniqueArr = arr.reduce((acc: NodeTypeAndIds[], cur) => {
    const hasDuplicate = acc.some(item => item.id === cur.id && item.element === cur.element);
    if (!hasDuplicate) {
      acc.push(cur);
    }
    return acc;
  }, []);
  return uniqueArr;
}

// 图标数组
export const iconArr = [
  {
    label: NodeTypeDisplay[NodeType.TRANSFORMER], // 变压器
    value: NodeType.TRANSFORMER,
    img: transformer,
  },
  {
    label: NodeTypeDisplay[NodeType.ANTI_BACKFLOW_DEVICE], // 防逆流装置
    value: NodeType.ANTI_BACKFLOW_DEVICE,
    img: antiBackflowDevice,
  },
  {
    label: NodeTypeDisplay[NodeType.ANTI_OVERCAPACITY_POINT], // 防超容点
    value: NodeType.ANTI_OVERCAPACITY_POINT,
    img: transformerMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.BACKUP_POWER], // 备用电源
    value: NodeType.BACKUP_POWER,
    img: backupBattery,
  },
  {
    label: NodeTypeDisplay[NodeType.BSA], // 储能站
    value: NodeType.BSA,
    img: energyStorage,
  },
  {
    label: NodeTypeDisplay[NodeType.BSA_BILLING_POINT], // 储能计费点
    value: NodeType.BSA_BILLING_POINT,
    img: bsaMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.BSA_GRID_CONNECTION_BILLING_POINT], // 储能并网点
    value: NodeType.BSA_GRID_CONNECTION_BILLING_POINT,
    img: bsaMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.CHARGING_STATION], // 充电站
    value: NodeType.CHARGING_STATION,
    img: charger,
  },
  {
    label: NodeTypeDisplay[NodeType.CHARGING_STATION_BILLING_POINT], // 充电站计费点
    value: NodeType.CHARGING_STATION_BILLING_POINT,
    img: loadMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.FCS_CONTROLLER], // 就地控制器
    value: NodeType.FCS_CONTROLLER,
    img: FCS,
  },
  {
    label: NodeTypeDisplay[NodeType.GATEWAY], // 网关
    value: NodeType.GATEWAY,
    img: gateway,
  },
  {
    label: NodeTypeDisplay[NodeType.GRID_ACCESS_BILLING_POINT], // 总进线计费点
    value: NodeType.GRID_ACCESS_BILLING_POINT,
    img: soureMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.GRID_ACCESS_POINT], // 电网接入点
    value: NodeType.GRID_ACCESS_POINT,
    img: poweGrid,
  },
  {
    label: NodeTypeDisplay[NodeType.LOAD], // 负载
    value: NodeType.LOAD,
    img: load,
  },
  {
    label: NodeTypeDisplay[NodeType.LOAD_BILLING_POINT], // 负载计费点
    value: NodeType.LOAD_BILLING_POINT,
    img: loadMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.PVA], // 光伏站
    value: NodeType.PVA,
    img: PV,
  },
  {
    label: NodeTypeDisplay[NodeType.PVA_BILLING_POINT], // 光伏计费点
    value: NodeType.PVA_BILLING_POINT,
    img: soureMeter,
  },
  {
    label: NodeTypeDisplay[NodeType.DCAC], // 交直流转换器
    value: NodeType.DCAC,
    img: DCAC,
  },
  {
    label: NodeTypeDisplay[NodeType.DC], // 直流转换器
    value: NodeType.DC,
    img: DC,
  },
  {
    label: NodeTypeDisplay[NodeType.OTHER], // 其他
    value: NodeType.OTHER,
    img: other,
  },
  {
    label: '汇流箱', // 其他
    value: 'CombinerBox',
    img: combinerBox,
  },
];
