import { Graph, Node } from '@antv/x6';
import { register } from '@antv/x6-react-shape';
import { TreeNode } from './type';
import { FcsList, GateWayList, NodeType, SwitchSelectDeviceList } from '@/pages/SchedulingTopology/type';
import GridSide from './components/GridSide';
import GridAccessPoint from './components/GridAccessPoint';
import Meters from './components/Meters';
import Transformer from './components/Transformer';
import BusBar from './components/BusBar';
import PVa from './components/PVa';
import Bsa from './components/Bsa';
import Load from './components/Load';
import GateWay from './components/GateWay';
import ChargingStation from './components/ChargingStation';
import BackupPower from './components/BackupPower';
import AntiBackflowDevice from './components/AntiBackflowDevice';
import FcsController from './components/FcsController';
import UniversalSwitch from './components/UniversalSwitch';
import OtherVertical from './components/OtherVertical';
import OtherTransverse from './components/OtherTransverse';

export default function (
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  switchSelectDeviceListRef?: SwitchSelectDeviceList[],
  configId?: number
) {
  registerGridSideNode();
  registerGridAccessPointNode(configId);
  registerBsaGridBillingPointNode(graphRef, allNodeDataRef, configId);
  registerTransformerNode(graphRef, allNodeDataRef, configId);

  registerBusBarNode(configId);
  registerPVaNode(graphRef, allNodeDataRef, configId);
  registerBsaNode(graphRef, allNodeDataRef, configId);
  registerLoadNode(graphRef, allNodeDataRef, configId);
  registerGateWayNode(allNodeDataRef, configId);
  registerChargingStationNode(configId);
  registerBackupPowerNode(graphRef, allNodeDataRef, configId);
  // 防逆流
  registerAntiBackflowDeviceNode(graphRef, allNodeDataRef, configId);
  // 电网计费点
  registerGridAccessBillingPointNode(graphRef, allNodeDataRef, configId);
  // 光伏计费点
  registerPVBillingPointNode(graphRef, allNodeDataRef, configId);
  // 防超容点
  registerAntiOverCapacityPointNode(graphRef, allNodeDataRef, configId);
  // 储能计费点
  registerBsaBillingPointNode(graphRef, allNodeDataRef, configId);
  // 充电站计费点
  registerChargingStationBillingPointNode(graphRef, allNodeDataRef, configId);
  // 负载计费点
  registerLoadBillingPointNode(graphRef, allNodeDataRef, configId);
  // 就地控制器
  registerFcsControllerNode(allNodeDataRef, configId);
  // 通用开关节点
  registerUniversalSwitchNode(graphRef, allNodeDataRef, switchSelectDeviceListRef, configId);
  // 自定义样式-垂直
  registerOtherVerticalNode(graphRef, allNodeDataRef, configId);
  // 自定义样式-水平
  registerOtherTransverseNode(graphRef, allNodeDataRef, configId);
}

// 电网侧
function registerGridSideNode() {
  register({
    shape: NodeType.GRID_SIDE,
    width: 160,
    height: 60,
    effect: ['data'],
    component: props => <GridSide {...props} />,
  });
}

// 电网接入点
function registerGridAccessPointNode(configId?: number) {
  register({
    shape: NodeType.GRID_ACCESS_POINT,
    width: 160,
    height: 100,
    effect: ['data'],
    component: props => <GridAccessPoint {...props} configId={configId} />,
  });
}

// 变压器
function registerTransformerNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.TRANSFORMER,
    width: 160,
    height: 172,
    effect: ['data'],
    component: props => (
      <Transformer {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />
    ),
  });
}

// 电网计费点
function registerGridAccessBillingPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.GRID_ACCESS_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

// 光伏计费点
function registerPVBillingPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.PVA_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}
// 防超容点
function registerAntiOverCapacityPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.ANTI_OVERCAPACITY_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}
// 储能计费点
function registerBsaBillingPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.BSA_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}
// 充电站计费点
function registerChargingStationBillingPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.CHARGING_STATION_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}
// 负载计费点
function registerLoadBillingPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.LOAD_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}
// 储能站并网点BSA_GRID_CONNECTION_BILLING_POINT
function registerBsaGridBillingPointNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.BSA_GRID_CONNECTION_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => <Meters {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

// 母线
function registerBusBarNode(configId?: number) {
  register({
    shape: NodeType.BUS_BAR,
    width: 280,
    height: 30,
    effect: ['data'],
    component: props => <BusBar {...props} configId={configId} />,
  });
}

// 光伏站
function registerPVaNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.PVA,
    width: 160,
    height: 230,
    effect: ['data'],
    component: props => <PVa {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

// 储能站
function registerBsaNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.BSA,
    width: 160,
    height: 230,
    effect: ['data'],
    component: props => <Bsa {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

// 负载
function registerLoadNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.LOAD,
    width: 160,
    height: 200,
    effect: ['data'],
    component: props => <Load {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

// 网关
function registerGateWayNode(allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.GATEWAY,
    width: 160,
    height: 40,
    effect: ['data'],
    component: props => <GateWay {...props} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

// 充电站Charging station
function registerChargingStationNode(configId?: number) {
  register({
    shape: NodeType.CHARGING_STATION,
    width: 160,
    height: 140,
    effect: ['data'],
    component: props => <ChargingStation {...props} configId={configId} />,
  });
}

// 备用电源
function registerBackupPowerNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.BACKUP_POWER,
    width: 160,
    height: 160,
    effect: ['data'],
    component: props => (
      <BackupPower {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />
    ),
  });
}

// 防逆流装置
function registerAntiBackflowDeviceNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.ANTI_BACKFLOW_DEVICE,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <AntiBackflowDevice {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />
    ),
  });
}

// 就地控制器
function registerFcsControllerNode(allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.FCS_CONTROLLER,
    width: 160,
    height: 40,
    effect: ['data'],
    component: props => <FcsController {...props} allNodeDataRef={allNodeDataRef} configId={configId} />,
  });
}

const registerUniversalSwitchNode = (
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  switchSelectDeviceListRef?: SwitchSelectDeviceList[],
  configId?: number
) => {
  register({
    shape: NodeType.UNIVERSAL_JOINT_OPENING,
    width: 200,
    height: 104,
    effect: ['data'],
    component: props => (
      <UniversalSwitch
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        switchSelectDeviceListRef={switchSelectDeviceListRef}
        configId={configId}
      />
    ),
  });
};
// 自定义样式-垂直
function registerOtherVerticalNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.OTHER_VERTICAL,
    width: 160,
    height: 172,
    effect: ['data'],
    component: props => (
      <OtherVertical {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />
    ),
  });
}

// 自定义样式-水平
function registerOtherTransverseNode(graphRef?: Graph, allNodeDataRef?: TreeNode[], configId?: number) {
  register({
    shape: NodeType.OTHER_TRANSVERSE,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <OtherTransverse {...props} graphRef={graphRef} allNodeDataRef={allNodeDataRef} configId={configId} />
    ),
  });
}
