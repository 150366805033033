import { FormInstance, Space } from 'antd';
import React, { useState } from 'react';
import { Button as MaxtropyButton, Modal } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import { NodeType } from '@/pages/SchedulingTopology/type';
import { Graph } from '@antv/x6';

interface Props {
  isDetail: boolean;
  styles: {
    readonly [key: string]: string;
  };
  form?: FormInstance;
  dispatchGroupId?: number;
  createMethod?: any;
  updateMethod?: any;
  isEdit?: boolean;
  onCancel?: () => void;
  forceUpdate?: () => void;

  getFinishedValues?: (values: any) => void;
  meterType?: NodeType;
  graphRef?: Graph;
}
const Footer: React.FC<Props> = ({
  form,
  isDetail,
  styles,
  createMethod,
  updateMethod,
  dispatchGroupId,
  onCancel,
  getFinishedValues,
  meterType,
  graphRef,
}) => {
  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const [loading, setLoading] = useState<boolean>(false); // 按钮增加防抖

  const onConfirm = () => {
    form?.validateFields().then(values => {
      setLoading(true);
      const { id } = values;
      const apiMethod = isNil(id) ? createMethod : updateMethod;
      // 如果是就地控制器，有校验
      if (meterType === NodeType.FCS_CONTROLLER) {
        const allNodes = graphRef?.getNodes();
        const hasBsaNode = allNodes?.find(
          i =>
            i.getData().element === NodeType.BSA && i.getData().value?.bsaId === form.getFieldValue('bsaItemIdList')[0]
        );

        if (hasBsaNode) {
          apiMethod?.({
            ...values,
            meterType,
            monitorId: form.getFieldValue('monitorId'),
            dispatchUetStructId: +topologyId!,
            dispatchGroupId,
          })
            .then((res: any) => {
              getFinishedValues?.({
                ...res,
                originId: id,
                isEdit: isNil(id) ? false : true,
              });
              onCancel?.();
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          Modal.error({
            title: '请先添加储能站',
          });
        }
      } else
        apiMethod?.({
          ...values,
          meterType,
          monitorId:
            meterType === NodeType.BSA_GRID_CONNECTION_BILLING_POINT
              ? form.getFieldValue('monitorId')[1]
              : form.getFieldValue('monitorId'),
          dispatchUetStructId: +topologyId!,
          dispatchGroupId,
        })
          .then((res: any) => {
            getFinishedValues?.({
              ...res,
              originId: id,
              isEdit: isNil(id) ? false : true,
            });
            onCancel?.();
          })
          .finally(() => {
            setLoading(false);
          });
    });
  };
  return (
    <>
      <Space size={8}>
        {isDetail && (
          <>
            <MaxtropyButton type="default" className={styles.defaultBtn} onClick={onCancel}>
              关闭
            </MaxtropyButton>
          </>
        )}
        {!isDetail && (
          <>
            <MaxtropyButton type="default" className={styles.defaultBtn} onClick={onCancel}>
              取消
            </MaxtropyButton>
            <MaxtropyButton type="primary" loading={loading} onClick={onConfirm}>
              确定
            </MaxtropyButton>
          </>
        )}
      </Space>
    </>
  );
};

export default Footer;
