import { isFullDay } from '@/pages/StrategyTemplate/utils';
import { Form } from '@maxtropy/components';
import React from 'react';
import PeakItem from '../PeakItem';

const ChargeDisChangeTime = () => {
  return (
    <Form.Item noStyle dependencies={['timePeriodGranularity']}>
      {({ getFieldValue }) => {
        const displayType = getFieldValue('timePeriodGranularity');
        if (displayType === undefined) return null;
        return (
          <Form.Item
            noStyle
            name="price"
            rules={[
              {
                validator: (_, value) => {
                  if (isFullDay(value || [], displayType)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('请选满24小时');
                },
                validateTrigger: 'onBlur',
              },
            ]}
          >
            <PeakItem displayType={displayType} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default ChargeDisChangeTime;
